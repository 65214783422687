import { FC } from 'react';
import { Box, Dialog, IconButton, styled, Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setIsWalletModalOpen } from 'state/modal/modalSlice';
import { Close } from '@mui/icons-material';
import { useWeb3React } from '@web3-react/core';
import { metamaskIcon } from 'config/constants/assets';
import { LinkButton } from 'components/common/Button';
import useAuth from 'hooks/useAuth';

const ModalDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container > .MuiPaper-root': {
    borderRadius: '12px',
    maxWidth: '416px',
    width: '100%',
    background: theme.palette.mode === 'dark' ? '#1F2937' : '#FFFFFF',
    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
    padding: '24px 28px',
    border: '1px solid #FFFFFF',
  },
}));

const TitleRow = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const DisconnectRow = styled(Box)(({ theme }) => ({
  marginTop: '12px',
  '& button': {
    padding: '0px',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.mode === 'dark' ? '#6B7280' : '#9CA3AF',
  },
}));

// wallet image
const WalletImg = styled('img')({
  width: '24px',
  height: '24px',
  marginRight: '8px',
});

// modal title
const ModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#9CA3AF',
  fontSize: '14px',
  lineHeight: '14px',
}));

// close button
const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
  padding: '0',
}));

const WalletModal: FC = () => {
  const { isWalletModalOpen } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();
  const { logout } = useAuth();
  const { account } = useWeb3React();
  const theme = useTheme();

  const isDarkMode = theme.palette.mode === 'dark';

  const onClose = () => {
    dispatch(setIsWalletModalOpen(false));
  };

  const onDisconnect = () => {
    logout();
    onClose();
  };

  return (
    <ModalDialog maxWidth="xs" onClose={onClose} open={isWalletModalOpen}>
      <Box>
        <TitleRow>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <WalletImg alt="web3 wallet" src={isDarkMode ? metamaskIcon.dark : metamaskIcon.light} />
            <ModalTitle>{`Account ending in ${account && account.slice(-4)}`}</ModalTitle>
          </Box>
          <CloseButton onClick={onClose}>
            <Close sx={{ width: 24, height: 24, color: '#9CA3AF' }} />
          </CloseButton>
        </TitleRow>
        <DisconnectRow>
          <LinkButton onClick={onDisconnect}>Disconnect Wallet</LinkButton>
        </DisconnectRow>
      </Box>
    </ModalDialog>
  );
};

export { WalletModal };
