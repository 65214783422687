import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, styled, useTheme } from '@mui/material';

import { headerLogo } from 'config/constants/assets';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setIsWalletConnectModalOpen, setIsWalletModalOpen } from 'state/modal/modalSlice';
import useAuth from 'hooks/useAuth';
import { ConnectorNames } from 'types/wallet';
import { useWeb3React } from '@web3-react/core';
import { shortenAddressHeader } from 'utils/address';
import { connectorLocalStorageKey } from 'config/constants/wallet';
import { GEOLOCATION_BLACKLIST } from 'config/constants';

const AppHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #e5e7eb',
  background: theme.palette.mode === 'dark' ? '#111827' : '#FFFFFF',
  borderColor: theme.palette.mode === 'dark' ? '#4B5563' : '#D6D3D1',
  height: '80px',
  padding: '24px 24px',
  position: 'sticky',
  top: '0px',
  zIndex: 150,

  [theme.breakpoints.up('md')]: {
    padding: '24px 0px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '24px 0px',
  },
}));

const LogoImgSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  [theme.breakpoints.up('md')]: {
    width: '220px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '300px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '410px',
  },
}));

const LogoImg = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '0px auto',
  },
  [theme.breakpoints.up('lg')]: {
    margin: '0px',
    marginLeft: '70px',
  },
  [theme.breakpoints.up('xl')]: {},
}));

const MenuSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    paddingRight: '28px',
  },
  [theme.breakpoints.up('lg')]: {
    paddingRight: '64px',
  },
}));

const ConnectWalletButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '19px',
  padding: '8px 16px',
  border: '2px solid #ffffff',
  borderColor: theme.palette.mode === 'dark' ? '#FFFFFF' : '#44403C',
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#44403C',
  marginLeft: '12px',
  width: '160px',
  overflowWrap: 'break-word',

  '&:hover': {
    borderWidth: '2px',
  },
}));

const WaringCard = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '45px',
  left: '0px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '11px',
  lineHeight: '14px',
  padding: '8px 4px',
  border: '2px solid #ffffff',
  borderColor: theme.palette.mode === 'dark' ? '#FFFFFF' : '#44403C',
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#44403C',
  background: theme.palette.mode === 'dark' ? '#111827' : '#FFFFFF',
  borderRadius: '8px',
  marginLeft: '12px',
  width: '160px',

  '&:hover': {
    borderWidth: '2px',
  },
}));

const Header: FC = () => {
  const [isGeoBlockShown, setGeoBlockShown] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const theme = useTheme();
  const navigate = useNavigate();
  const { login } = useAuth();
  const { geolocation } = useAppSelector((state) => state.geolocation);

  const isDarkMode = theme.palette.mode === 'dark';
  const isBlackListed = geolocation?.country === undefined || GEOLOCATION_BLACKLIST.includes(geolocation?.country);

  const persistConnect = () => {
    const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames;
    if (connectorId) {
      login(connectorId);
    }
  };

  const onConnectWallet = () => {
    if (isBlackListed) {
      setGeoBlockShown(true);
      setTimeout(() => {
        setGeoBlockShown(false);
      }, 5000);
      return;
    }

    if (!account) {
      dispatch(setIsWalletConnectModalOpen(true));
    } else {
      dispatch(setIsWalletModalOpen(true));
    }
  };

  useEffect(() => {
    persistConnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geolocation]);

  return (
    <AppHeader>
      <LogoImgSection onClick={() => navigate('/')}>
        <LogoImg alt="logo" src={isDarkMode ? headerLogo.dark : headerLogo.light} />
      </LogoImgSection>
      <MenuSection>
        <ConnectWalletButton onClick={onConnectWallet} variant="outlined">
          {account ? shortenAddressHeader(account) : 'Connect wallet'}
        </ConnectWalletButton>
        {isGeoBlockShown && geolocation?.country && (
          <WaringCard>
            <Box>{`You are accessing this website from ${geolocation?.country}.`}</Box>
            <Box sx={{ marginTop: '4px' }}>
              {`The following countries are geoblocked: ${GEOLOCATION_BLACKLIST.join(', ')}.`}
            </Box>
          </WaringCard>
        )}
      </MenuSection>
    </AppHeader>
  );
};

export default Header;
