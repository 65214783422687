import React, { FC, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { fetchGeolocation, fetchLendGlobalDataAsync, fetchVaultGlobalDataAsync } from 'state/actions';
import { useAppDispatch } from 'state/hooks';
import MainLayout from 'layouts/MainLayout';

const VaultsPage = React.lazy(() => import('./pages/Vaults'));
const RequestsPage = React.lazy(() => import('./pages/Requests'));
const VaultDetail1Page = React.lazy(() => import('./pages/VaultDetail1'));
const Leaderboardpage = React.lazy(() => import('./pages/Leaderboard'));
const LeveragePage = React.lazy(() => import('./pages/Leverage'));

const Router: FC = () => {
  const dispatch = useAppDispatch();

  const fetchData = async () => {
    dispatch(fetchVaultGlobalDataAsync());
    dispatch(fetchLendGlobalDataAsync());
  };

  useEffect(() => {
    dispatch(fetchGeolocation());

    fetchData();
    setInterval(() => {
      fetchData();
    }, 60000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes = useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, element: <Navigate replace to="/vaults" /> },
        // { path: '/dashboard', element: <OverviewPage /> },
        { path: '/vaults', element: <VaultsPage /> },
        // { path: '/vaults', element: <VaultDetail1Page /> },
        { path: '/vaults/:vaultAddress', element: <VaultDetail1Page /> },
        { path: '/leaderboard', element: <Leaderboardpage /> },
        { path: '/leverage', element: <LeveragePage /> },
        { path: '/requests', element: <RequestsPage /> },
      ],
    },

    { path: '*', element: <Navigate replace to="/" /> },
  ]);

  return routes;
};

export default Router;
