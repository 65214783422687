import SpiceLightLogo from 'assets/images/spice-logo-light.svg';
import SpiceDarkLogo from 'assets/images/spice-logo-dark.svg';
import SpiceSmall from 'assets/images/spice-logo.png';
import OverviewBgLightImg from 'assets/images/overview-bg-light.png';
import OverviewBgDarkImg from 'assets/images/overview-bg-dark.png';
import AccountDefaultImg from 'assets/images/default-account.png';
// web3 wallet
import MetamaskIcon from 'assets/images/metamask.png';
import TrendingUpIcon from 'assets/images/icon-trending-up.svg';
import TrendingDownIcon from 'assets/images/icon-trending-down.svg';
import TriangleDownIcon from 'assets/images/triangle-down.svg';
import TriangleUpIcon from 'assets/images/triangle-up.svg';
import BtcIcon from 'assets/images/bitcoin.svg';
import EthIcon from 'assets/images/ethereum.svg';
import SolIcon from 'assets/images/solana.svg';

// leverage
import LeverageTooltipLightIcon from 'assets/images/question_mark_light.png';
import LeverageTooltipDarkIcon from 'assets/images/question_mark_dark.png';

// arrow
import ArrowLeftLightIcon from 'assets/images/arrow-left-light.svg';
import ArrowLeftDarkIcon from 'assets/images/arrow-left-dark.svg';
import ArrowRightIcon from 'assets/images/arrow-right.svg';

// tokens
import ETHIcon from 'assets/images/tokens/eth.svg';
import WETHIcon from 'assets/images/tokens/weth.svg';

export interface ImageType {
  light: string;
  dark: string;
}

export const headerLogo: ImageType = {
  light: SpiceLightLogo,
  dark: SpiceDarkLogo,
};

export const overviewBgImg: ImageType = {
  light: OverviewBgLightImg,
  dark: OverviewBgDarkImg,
};

export const accountImg: ImageType = {
  light: AccountDefaultImg,
  dark: AccountDefaultImg,
};

export const vaultDefaultLogo: ImageType = {
  light: SpiceSmall,
  dark: SpiceSmall,
};

export const metamaskIcon: ImageType = {
  light: MetamaskIcon,
  dark: MetamaskIcon,
};

export const trendingIcon = {
  up: TrendingUpIcon,
  down: TrendingDownIcon,
};

export const triangleIcon = {
  up: TriangleUpIcon,
  down: TriangleDownIcon,
};

export const tokenIcons = {
  btc: BtcIcon,
  eth: EthIcon,
  sol: SolIcon,
};

// leverage
export const leverageTooltipIcon: ImageType = {
  light: LeverageTooltipDarkIcon,
  dark: LeverageTooltipLightIcon,
};

// arrow

const arrowLeftIcon: ImageType = {
  light: ArrowLeftLightIcon,
  dark: ArrowLeftDarkIcon,
};

export const arrowIcons = {
  left: arrowLeftIcon,
  right: ArrowRightIcon,
};

export const wantTokenIcons: any = {
  WETH: WETHIcon,
  ETH: ETHIcon,
};
