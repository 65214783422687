import { FC, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  Slider,
  styled,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useAppSelector } from 'state/hooks';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, providers, utils } from 'ethers';

import { ContainedInput } from 'components/common/Input';
import { ContainedButton } from 'components/common/Button';
import { LeverageConfirmModal } from 'components/Modal';
import { GEOLOCATION_BLACKLIST } from 'config/constants';
import { leverageTooltipIcon } from 'config/constants/assets';
import { PortfolioInfo } from 'types/leverage';
import { getBalanceInEther } from 'utils/formatBalance';
import { getLoanTerms } from 'utils/lend';
import { calculateBorrowApr, getNetApy } from 'utils/apy';
import { getWethAddress } from 'utils/addressHelpers';
import { activeChainId } from 'utils/web3';
import { DAY_IN_SECONDS, YEAR_IN_SECONDS } from 'config/constants/time';
import { getLenderByLoanId } from 'state/lend/fetchGlobalLend';

const ModalDialog = styled(Dialog)(
  ({ theme, status, visibility }: { theme: Theme; status: string; visibility: string }) => ({
    '.MuiDialog-container > .MuiPaper-root': {
      borderRadius: '12px',
      maxWidth: status === 'SUCCESS' || status === 'FAILED' ? '444px' : '567px',
      width: '100%',
      background: theme.palette.mode === 'dark' ? '#1F2937' : '#FFFFFF',
      boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
      border: '1px solid #FFFFFF',
      visibility,
    },
  })
);

const TabBox = styled(Box)(({ theme }) => ({
  background: theme.palette.mode === 'dark' ? '#374151' : '#F5F5F4',
}));

const ModeTab = styled(Grid)(({ theme, active }: { theme: Theme; active: number }) => ({
  borderWidth: '0px',
  borderRadius: active ? '8px 8px 0px 0px' : '0px',
  // eslint-disable-next-line no-nested-ternary
  background: active
    ? theme.palette.mode === 'dark'
      ? '#1F2937'
      : '#FFFFFF'
    : theme.palette.mode === 'dark'
    ? '#374151'
    : '#F5F5F4',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '15px',
  padding: '13px 12px',
  textAlign: 'center',
  cursor: 'pointer',
}));

const ContentBox = styled(Box)(() => ({
  padding: '20px 42px',
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  fontSize: '20px',
  lineHeight: '24px',
}));

const InputRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#000000',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: '400',
  width: '100px',
}));

// slider
const SliderLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#6B7280',
  fontSize: '8px',
  lineHeight: '10px',
  fontWeight: '400',
}));

const SliderValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '400',
  marginTop: '4px',
}));

const Divider = styled(Box)(() => ({
  background: '#D1D5DB',
  width: '1px',
  height: '100%',
}));

const TermsSlider = styled(Slider)(({ theme }) => ({
  height: 2,
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#D1D5DB',

  '& .MuiSlider-rail': {
    opacity: '1',
  },
  '& .MuiSlider-track': {
    border: 'none',
    color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#D1D5DB',
  },
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
    color: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
    backgroundColor: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    background: 'transparent',
    marginTop: '10px',
  },
}));

// tooltip
const ToBeStyledTooltip = ({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  marginBottom: '20px',
  marginRight: '0px !important',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '10px',
  lineHeight: '12px',
  padding: '10px 10px',
  border: '1px solid #BBBFC6',
  background: theme.palette.mode === 'dark' ? '#111827' : '#D6D3D1',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  borderColor: theme.palette.mode === 'dark' ? '#BBBFC6' : '#78716C',
  borderRadius: '5px',
}));

const TooltipIcon = styled(Box)(() => ({
  position: 'absolute',
  right: '-20px',
  top: '-6px',
}));

interface Props {
  info: PortfolioInfo;
  onClose: () => void;
}

const ManageLeverageModal: FC<Props> = ({ info, onClose }) => {
  const [status, setStatus] = useState<string>('INITIAL'); // 1.initial, 2.confirming,
  const [mode, setMode] = useState<string>('Increase');
  const [sliderStep, setSliderStep] = useState<number>(0);
  const [targetAmount, setTargetAmount] = useState<string>('');

  // contract call params
  const [termsParam, setTermsParam] = useState<any>();
  const [signatureParam, setSignatureParam] = useState<any>();
  // loan
  const [loanLender, setLoanLender] = useState<string>('');

  const { account, library } = useWeb3React();
  const theme = useTheme();
  const { geolocation } = useAppSelector((state) => state.geolocation);
  const { defaultVault, activeVault, leverageVaults } = useAppSelector((state) => state.vault);
  const { data: lendData } = useAppSelector((state) => state.lend);

  const currentLend = lendData.find((row: any) => row.address === info?.lendAddr);
  const currentVault = activeVault || defaultVault;

  const { loanId, repayAmount, balance } = info.loan;
  const collateralValue = getBalanceInEther(info.value);
  const loanValue = getBalanceInEther(balance || BigNumber.from(0));
  const repayValue = getBalanceInEther(repayAmount || BigNumber.from(0));
  const originMaxLtv = currentLend?.loanRatio || 0;
  const maxRepayment = getBalanceInEther(repayAmount || BigNumber.from(0));
  const nftId = info?.tokenId;
  const isValidNftId = nftId !== undefined;
  const isValidAmount = Number(sliderStep) > 0;

  const isBlackListed = geolocation?.country === undefined || GEOLOCATION_BLACKLIST.includes(geolocation?.country);

  const loanLenderVault = leverageVaults.find((row: any) => row.address === loanLender);
  const isDarkMode = theme.palette.mode === 'dark';

  // max leverage calculation to prevent utilization is greater than 1
  const lenderWethAvailable = getBalanceInEther(loanLenderVault?.wethBalance || BigNumber.from(0));
  const interesteAccrued = repayValue - loanValue;

  const leverageAvailable = Math.max(
    0,
    originMaxLtv < 0.9
      ? originMaxLtv * (collateralValue - interesteAccrued * 2)
      : originMaxLtv * (collateralValue - loanValue - interesteAccrued * 2)
  );

  const maxLeverage = Math.min(leverageAvailable, lenderWethAvailable);
  const maxLtv = lenderWethAvailable > leverageAvailable ? originMaxLtv : maxLeverage / (collateralValue - loanValue);

  const getAmount = (step: number) => {
    if (mode === 'Increase') {
      return loanValue + (step / 100) * Math.max(0, maxLeverage - loanValue);
    }
    return (step / 100) * maxRepayment;
  };

  const fetchLoanLender = async () => {
    if (currentLend?.lenderNote) {
      const loanLenderAddr = await getLenderByLoanId(currentLend.lenderNote, loanId);
      setLoanLender(loanLenderAddr);
    }
  };

  useEffect(() => {
    if (loanId > 0) {
      fetchLoanLender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanId]);

  const getRefinanceApr = () => {
    if (!loanLenderVault) return 0;

    const additionalDebt = mode === 'Increase' ? getAmount(sliderStep) - loanValue : getAmount(sliderStep);
    const total = getBalanceInEther(loanLenderVault?.totalAssets || BigNumber.from(0));
    const available = getBalanceInEther(loanLenderVault?.wethBalance || BigNumber.from(0));
    const duration = (info?.loan?.terms.duration || 0) / DAY_IN_SECONDS;
    const ltv =
      originMaxLtv > 1
        ? getAmount(sliderStep) / (collateralValue + additionalDebt)
        : getAmount(sliderStep) / collateralValue;

    return 100 * calculateBorrowApr(ltv, additionalDebt, total, available, duration);
  };

  const getBorrowApr = () => {
    if (mode === 'Decrease') return 100 * (info?.borrowApr || 0);
    return getRefinanceApr();
  };

  const calculateNetApy = () => {
    const loanDuration = 28 * DAY_IN_SECONDS;
    const m = YEAR_IN_SECONDS / loanDuration;
    const borrowApr = getBorrowApr() / 100;
    // eslint-disable-next-line no-restricted-properties
    const borrowApy = Math.pow(1 + borrowApr / m, m) - 1;
    const vaultApy = (defaultVault?.apr || 0) / 100;
    const { value } = info;
    const additionalDebt = mode === 'Increase' ? getAmount(sliderStep) - loanValue : getAmount(sliderStep);
    return 100 * getNetApy(getBalanceInEther(value) + additionalDebt, vaultApy, repayValue + additionalDebt, borrowApy);
  };

  const onCloseLeverageModal = () => {
    if (status === 'Confirming') return;
    onClose();
  };

  const onChangeMode = (newMode: string) => {
    if (status === 'Confirming') return;
    if (mode !== newMode) {
      setMode(newMode);
      setSliderStep(0);
      setTargetAmount('');
    }
  };

  const onChangeTerms = (e: any) => {
    if (status === 'Confirming') return;
    if (mode === 'Increase' && maxLeverage === 0) return;
    if (mode === 'Decrease' && maxRepayment === 0) return;

    if (mode === 'Increase') {
      setSliderStep(e.target.value);
      setTargetAmount(getAmount(e.target.value).toFixed(4));
    } else {
      setSliderStep(e.target.value);
      setTargetAmount(getAmount(e.target.value).toFixed(4));
    }
  };

  const onChangeTargetAmount = (e: any) => {
    if (status === 'Confirming') return;

    if (Number(e.target.value) >= 0) {
      const sliderMax = mode === 'Increase' ? maxLeverage : maxRepayment;
      if (sliderMax === 0) return;

      // when the amount is greater than max leverage
      if (e.target.value > sliderMax) {
        setTargetAmount(Number(sliderMax).toFixed(4));
        setSliderStep(100);
        return;
      }

      if (mode === 'Increase') {
        // when the amount is smaller than repay value
        if (Number(e.target.value) < loanValue) {
          setTargetAmount(e.target.value);
          setSliderStep(0);
          return;
        }
        setTargetAmount(e.target.value);
        setSliderStep((100 * (Number(e.target.value) - loanValue)) / (maxLeverage - loanValue));
      } else {
        setTargetAmount(e.target.value);
        setSliderStep((100 * Number(e.target.value)) / sliderMax);
      }
    }
  };

  // increase loan
  const handleIncrease = async (additionalAmount: string) => {
    try {
      const terms = {
        loanAmount: info.loanAmount.toString(),
        duration: 14 * DAY_IN_SECONDS, // 14 days
        collateralAddress: currentVault?.address,
        collateralId: nftId,
        borrower: account,
        currency: getWethAddress(),
        additionalLoanAmount: additionalAmount,
        additionalDuration: 0,
      };

      const domain = {
        name: 'Spice Finance',
        version: '1',
        chainId: activeChainId,
      };

      const LoanTermsRequestType = [
        {
          name: 'loanAmount',
          type: 'uint256',
        },
        {
          name: 'duration',
          type: 'uint32',
        },
        {
          name: 'collateralAddress',
          type: 'address',
        },
        {
          name: 'collateralId',
          type: 'uint256',
        },
        {
          name: 'borrower',
          type: 'address',
        },
        {
          name: 'currency',
          type: 'address',
        },
        {
          name: 'additionalLoanAmount',
          type: 'uint256',
        },
        {
          name: 'additionalDuration',
          type: 'uint32',
        },
      ];

      const types = {
        LoanTerms: LoanTermsRequestType,
      };

      const provider = new providers.Web3Provider(library);
      const signer = provider.getSigner();
      // eslint-disable-next-line no-underscore-dangle
      const signature = await signer._signTypedData(domain, types, terms);

      // Call backend api
      const res = await getLoanTerms(
        terms,
        signature,
        additionalAmount === '0' ? 'extend' : 'increase',
        activeChainId,
        loanId
      );

      const loanterms = {
        ...res.data.data.loanterms,
        loanAmount: BigNumber.from(res.data.data.loanterms.loanAmount.toString()),
      };
      delete loanterms.repayment;

      setTermsParam(loanterms);
      setSignatureParam(res.data.data.signature);

      setTimeout(() => {
        setStatus('SUCCESS');
      }, 1000);
    } catch (err: any) {
      setStatus('INITIAL');
    }
  };

  // decrease loan
  const handleDecrease = async () => {
    setTimeout(() => {
      setStatus('SUCCESS');
    }, 1000);
  };

  const handleAccept = async () => {
    if (isBlackListed) return;

    setStatus('Confirming');

    if (mode === 'Increase') {
      const additionalAmount = utils.parseEther((getAmount(sliderStep) - loanValue).toFixed(18)).toString();
      await handleIncrease(additionalAmount);
    }
    if (mode === 'Decrease') {
      await handleDecrease();
    }
    if (mode === 'Refinance') {
      await handleIncrease('0');
    }
  };

  const onCloseConfirmModal = () => {
    setStatus('INITIAL');
    onClose();
  };

  const onBack = () => {
    setStatus('INITIAL');
  };

  const getButtonName = () => {
    if (status === 'Confirming')
      return (
        <>
          Processing...
          <CircularProgress size={16} sx={{ marginLeft: '10px', color: 'white' }} />
        </>
      );

    if (status === 'INITIAL') return 'Accept';

    return 'Accept';
  };

  const valueLabelFormat = () => (
    <Box sx={{ textAlign: 'center' }}>
      {mode === 'Increase' && <SliderLabel>Leverage Amount</SliderLabel>}
      {mode === 'Decrease' && <SliderLabel>Repayment Amount</SliderLabel>}
      <SliderValue>{`Ξ${getAmount(sliderStep).toFixed(4)}`}</SliderValue>
    </Box>
  );

  const maxLeverageTooltipContent = () => (
    <>
      <Box sx={{ maxWidth: '198px' }}>
        {`There is only ${Number(lenderWethAvailable).toFixed(
          2
        )} WETH available in the Leverage Vault to borrow from. `}
      </Box>
    </>
  );

  const getAdditionalAmnout = () => {
    if (mode === 'Refinance') return 0;
    if (mode === 'Increase') return getAmount(sliderStep) - loanValue;
    return getAmount(sliderStep);
  };

  const isAcceptDisabled =
    !isValidNftId ||
    (mode !== 'Refinance' && !isValidAmount) ||
    status === 'Confirming' ||
    (mode === 'Increase' && (getAmount(sliderStep) < loanValue || getAdditionalAmnout() === 0));

  return (
    <>
      {account && status === 'SUCCESS' && (
        <LeverageConfirmModal
          action={mode}
          amount={getAdditionalAmnout()}
          borrowApr={getBorrowApr()}
          info={info}
          isApproved
          isMax={sliderStep === 100}
          loanId={loanId}
          nftId={nftId || 0}
          onBack={onBack}
          onClose={onCloseConfirmModal}
          signatureParam={signatureParam}
          termsParam={termsParam}
          vault={currentVault}
        />
      )}
      <ModalDialog
        maxWidth="xs"
        onClose={onCloseLeverageModal}
        open
        status={status}
        theme={theme}
        visibility={status === 'INITIAL' || status === 'Confirming' ? 'visible' : 'hidden'}
      >
        <Box>
          <TabBox>
            <Grid container>
              <ModeTab
                active={mode === 'Increase' ? 1 : 0}
                item
                onClick={() => onChangeMode('Increase')}
                theme={theme}
                xs={4}
              >
                Increase
              </ModeTab>
              <ModeTab
                active={mode === 'Decrease' ? 1 : 0}
                item
                onClick={() => onChangeMode('Decrease')}
                theme={theme}
                xs={4}
              >
                Decrease
              </ModeTab>
              <ModeTab
                active={mode === 'Refinance' ? 1 : 0}
                item
                onClick={() => onChangeMode('Refinance')}
                theme={theme}
                xs={4}
              >
                Refinance
              </ModeTab>
            </Grid>
          </TabBox>
          <ContentBox>
            <Box>
              <ModalTitle>{`${mode} Leverage`}</ModalTitle>
            </Box>
            <Box sx={{ marginTop: '30px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
              {account && (
                <Box>
                  <InputRow>
                    <Label>NFT ID</Label>
                    <Box sx={{ width: '100%' }}>
                      <ContainedInput disabled id="nftId-input" name="nftId" placeholder="" value={nftId} />
                    </Box>
                  </InputRow>
                </Box>
              )}
              {mode === 'Decrease' && (
                <InputRow>
                  <Label>Debt Owed</Label>
                  <Box sx={{ width: '100%' }}>
                    <ContainedInput
                      disabled
                      id="debt-owed-input"
                      name="debtOwed"
                      placeholder=""
                      value={maxRepayment.toFixed(4)}
                    />
                  </Box>
                </InputRow>
              )}
              {(mode === 'Increase' || mode === 'Decrease') && (
                <>
                  <InputRow>
                    <Label>Terms</Label>
                    <Box sx={{ width: '100%' }}>
                      {mode === 'Increase' && (
                        <TermsSlider
                          disabled={status === 'Confirming'}
                          max={100}
                          min={loanValue >= maxLeverage ? 100 : 0}
                          onChange={onChangeTerms}
                          size="small"
                          step={10}
                          value={sliderStep}
                          valueLabelDisplay="on"
                          valueLabelFormat={valueLabelFormat}
                        />
                      )}
                      {mode === 'Decrease' && (
                        <TermsSlider
                          disabled={status === 'Confirming'}
                          max={100}
                          min={0}
                          onChange={onChangeTerms}
                          size="small"
                          step={10}
                          value={sliderStep}
                          valueLabelDisplay="on"
                          valueLabelFormat={valueLabelFormat}
                        />
                      )}
                    </Box>
                  </InputRow>
                  <InputRow>
                    <Label />
                    <Box sx={{ width: '100%', marginTop: '-40px' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box display="flex" gap="6px">
                          <Box>
                            <SliderLabel>Borrow APR</SliderLabel>
                            <SliderValue>{`${getBorrowApr().toFixed(2)}%`}</SliderValue>
                          </Box>
                          <Divider />
                          <Box>
                            <SliderLabel>Net APY</SliderLabel>
                            <SliderValue>
                              {`${(mode === 'Decrease' ? getBorrowApr() : calculateNetApy()).toFixed(2)}%`}
                            </SliderValue>
                          </Box>
                        </Box>

                        {mode === 'Increase' && (
                          <Box sx={{ textAlign: 'right' }}>
                            <Box sx={{ position: 'relative' }}>
                              <SliderLabel>Max Leverage / Max LTV</SliderLabel>
                              {maxLtv < originMaxLtv && (
                                <StyledTooltip placement="left-end" title={maxLeverageTooltipContent()}>
                                  <TooltipIcon>
                                    <img
                                      alt="logo"
                                      src={isDarkMode ? leverageTooltipIcon.dark : leverageTooltipIcon.light}
                                    />
                                  </TooltipIcon>
                                </StyledTooltip>
                              )}
                            </Box>
                            <SliderValue>{`Ξ${maxLeverage.toFixed(4)} / ${(maxLtv * 100).toFixed(2)}%`}</SliderValue>
                          </Box>
                        )}
                        {mode === 'Decrease' && (
                          <Box sx={{ textAlign: 'right' }}>
                            <SliderLabel>Max Repayment </SliderLabel>
                            <SliderValue>{`Ξ${maxRepayment.toFixed(4)}`}</SliderValue>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </InputRow>
                </>
              )}
              {mode === 'Refinance' && (
                <>
                  <InputRow>
                    <Label>Current APR</Label>
                    <Box sx={{ width: '100%' }}>
                      <ContainedInput
                        disabled
                        id="current-apr-input"
                        name="current-apr"
                        placeholder="Current APR"
                        value={(100 * (info?.borrowApr || 0)).toFixed(2)}
                      />
                    </Box>
                  </InputRow>
                  <InputRow>
                    <Label>New APR</Label>
                    <Box sx={{ width: '100%' }}>
                      <ContainedInput
                        disabled
                        id="new-apr-input"
                        name="apr"
                        placeholder="APR"
                        value={getRefinanceApr().toFixed(2)}
                      />
                    </Box>
                  </InputRow>
                  <InputRow>
                    <Label>New Term</Label>
                    <Box sx={{ width: '100%' }}>
                      <ContainedInput disabled id="new-term-input" name="new-term" placeholder="APR" value="14 days" />
                    </Box>
                  </InputRow>
                </>
              )}
            </Box>

            <InputRow>
              <Label />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: mode === 'Refinance' ? 'flex-end' : 'space-between',
                  gap: '16px',
                  marginTop: '20px',
                  width: '100%',
                }}
              >
                {(mode === 'Increase' || mode === 'Decrease') && (
                  <Box sx={{ width: '170px' }}>
                    <ContainedInput
                      disabled={status === 'Confirming'}
                      id="amount-input"
                      name="amount"
                      onChange={onChangeTargetAmount}
                      placeholder="Enter Amount"
                      value={targetAmount}
                    />
                  </Box>
                )}
                <ContainedButton
                  colorType="primary"
                  disabled={!account || isAcceptDisabled}
                  onClick={handleAccept}
                  sx={{ maxWidth: '160px' }}
                >
                  {getButtonName()}
                </ContainedButton>
              </Box>
            </InputRow>
          </ContentBox>
        </Box>
      </ModalDialog>
    </>
  );
};

export { ManageLeverageModal };
