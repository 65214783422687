import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { GEOLOCATION_API_KEY, GEOLOCATION_API_URL } from 'config/constants';
import { Geolocation } from 'types/geolocation';

const initialState: Geolocation = {
  geolocation: {},
};

export const geolocationSlice = createSlice({
  name: 'geolocation',
  initialState,
  reducers: {
    setGeolocation: (state, action) => {
      state.geolocation = action.payload;
    },
  },
});

export const { setGeolocation } = geolocationSlice.actions;

export const fetchGeolocation = () => async (dispatch: any) => {
  try {
    const res = await axios.get(`${GEOLOCATION_API_URL}?api_key=${GEOLOCATION_API_KEY}`);

    dispatch(setGeolocation(res.data));
  } catch (err: any) {
    console.log('Geolocation API call error:', err);
  }
};

export default geolocationSlice.reducer;
