import { FC, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate, useParams } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { Box, styled, Tooltip, tooltipClasses, TooltipProps, Typography, useTheme } from '@mui/material';
import { routes, RouteType, socialMedias, SocialMediaType } from 'config/constants/sidebar';
import { ThemeSwitch } from 'components/common/Switch';
import { getItem, setItem } from 'utils/helper';
import { ColorModeContext } from 'theme/index';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  fetchLendUserLoanDataAsync,
  fetchLendUserNftApproveDataAsync,
  fetchLendUserWethDataAsync,
  fetchVaultUserDataAsync,
  resetLendUserLoanData,
} from 'state/actions';
import { VaultInfo } from 'types/vault';
import { getSpiceFiLendingAddresses } from 'utils/addressHelpers';
import { accLoans } from 'utils/lend';

const AppSideBar = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderRight: '1px solid #4B5563',
  borderColor: theme.palette.mode === 'dark' ? '#4B5563' : '#D6D3D1',
  position: 'fixed',
  flexDirection: 'column',
  background: theme.palette.background.default,
  height: '100vh',
  paddingTop: '60px',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    width: '220px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '300px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '410px',
  },
}));

const SidebarMenuSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '0px',
  [theme.breakpoints.up('sm')]: {
    margin: '0px auto',
    gap: '32px',
  },
  [theme.breakpoints.up('lg')]: {
    gap: '32px',
    margin: '0px',
    marginLeft: '70px',
  },
}));

const SideBarMenuRow = styled(Box)(() => ({}));

const RouteItem = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  cursor: 'pointer',

  [theme.breakpoints.up('xs')]: {
    fontSize: '20px',
    lineHeight: '24px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
    lineHeight: '29px',
  },
}));

const RouteSubItem = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  marginTop: '24px',
  cursor: 'pointer',
}));

const VaultName = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '0px',
  marginLeft: '20px',
  padding: '6px',
  paddingLeft: '12px',

  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  cursor: 'pointer',
  borderTopLeftRadius: '8px',
  borderBottomLeftRadius: '8px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  [theme.breakpoints.up('xs')]: {
    fontSize: '18px',
    lineHeight: '22px',
    width: 'calc(100% + 17px)',
  },
  [theme.breakpoints.up('lg')]: {
    width: 'calc(100% - 20px)',
  },
}));

const SidebarSocialSection = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  // marginTop: '250px',
  marginBottom: '150px',
  marginLeft: '0px',
  [theme.breakpoints.up('sm')]: {
    margin: '0px auto',
    marginBottom: '150px',
  },
  [theme.breakpoints.up('lg')]: {
    margin: '0px',
    marginLeft: '70px',
    marginBottom: '150px',
  },
}));

const SocialRow = styled('a')(({ theme }) => ({
  margin: '24px 0px',
  width: '145px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '19px',
  cursor: 'pointer',
  color: theme.palette.grey.A400,

  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

const SocialItem = styled('span')(() => ({}));

const SocialIcon = styled('img')({});

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
  },
});

const COMING_SOON_TOOLTIP_TEXT = 'Coming soon';
const LEVERAGE_TOOLTIP_TEXT = 'You must hold a Prologue NFT';

const getVaultSidebarDisplayName = (isFungible: boolean, vaultType?: string) => {
  if (!vaultType) return '';

  if (isFungible) {
    if (vaultType === 'aggregator') return 'Flagship Vault';
    return 'Leverage Vault';
  }

  return 'Prologue Vault';
};

const SideBar: FC = () => {
  const { account } = useWeb3React();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { vaultAddress } = useParams();
  const { vaults, defaultVault } = useAppSelector((state) => state.vault);
  const { data: lendData } = useAppSelector((state) => state.lend);
  const colorMode = useContext(ColorModeContext);

  const pageName = pathname.replace('/', '').split('/')[0];
  const isDarkMode = theme.palette.mode === 'dark';
  const currentVault = vaults.find((row) => row.address === vaultAddress);
  const loans = accLoans(lendData);
  const isLeverageClickable = account && loans.length > 0;
  const userNfts = defaultVault?.userInfo?.nftsRaw || [];
  const lendAddrs = getSpiceFiLendingAddresses();
  const sortedVaults = [...vaults];

  const isDeprecated = (vault: any) => !!vault?.leverage && !!vault?.deprecated;

  useEffect(() => {
    dispatch(resetLendUserLoanData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    lendAddrs.map((lendAddr: any) => {
      dispatch(fetchLendUserLoanDataAsync(lendAddr, account, defaultVault?.address));
      dispatch(fetchLendUserWethDataAsync(lendAddr, account));
      return lendAddr;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, defaultVault]);

  useEffect(() => {
    if (userNfts.length === 0) return;

    lendAddrs.map((lendAddr: any) =>
      userNfts.map((row: any) => {
        dispatch(fetchLendUserNftApproveDataAsync(lendAddr, row.tokenId, account));
        return row;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userNfts.length]);

  useEffect(() => {
    if (vaults.length === 0) return;
    vaults.map((row: VaultInfo) => {
      dispatch(fetchVaultUserDataAsync(account, row));
      return row;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, vaults.length]);

  const onClickRouteItem = (item: RouteType) => {
    if (item.isComingSoon) return;
    navigate(item.url);
  };

  const onClickSocialItem = (e: React.MouseEvent<HTMLAnchorElement>, item: SocialMediaType) => {
    if (item.isComingSoon) {
      e.preventDefault();
    }
  };

  const onToggle = () => {
    colorMode.toggleColorMode();
    const themeMode = getItem('userThemeMode');
    setItem('userThemeMode', themeMode === 'light' ? 'dark' : 'light');
  };

  const renderSidebarMenuRow = (row: RouteType) => (
    <SideBarMenuRow
      onClick={() => {
        if (row.key === 'leverage' && !isLeverageClickable) return;
        onClickRouteItem(row);
      }}
    >
      <RouteItem
        sx={{
          color: row.key === pageName ? theme.palette.common.black : theme.palette.grey.A400,
          fontWeight: row.key === pageName ? 600 : 400,
        }}
      >
        {row.text}
      </RouteItem>
      {row.key === 'vaults' && pageName === 'vaults' && (
        <>
          {sortedVaults
            .sort((a: any, b: any) => (a?.apr > b?.apr ? -1 : 1))
            .map((vault: any) => {
              if (isDeprecated(vault)) return null;

              return (
                <RouteSubItem
                  key={vault.address}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/vaults/${vault.address}`);
                  }}
                >
                  <Box sx={{ minHeight: '34px' }} />
                  <VaultName
                    sx={{
                      textDecoration: isDeprecated(vault) ? 'line-through' : 'none',
                      background:
                        // eslint-disable-next-line no-nested-ternary
                        vault?.address === currentVault?.address ? (isDarkMode ? '#374151' : '#F5F5F4') : 'transparent',
                      color:
                        // eslint-disable-next-line no-nested-ternary
                        vault?.address === currentVault?.address ? (isDarkMode ? '#ffffff' : '#000000') : '#6B7280',
                    }}
                  >
                    {getVaultSidebarDisplayName(!!vault?.fungible, vault?.type)}
                  </VaultName>
                </RouteSubItem>
              );
            })}
        </>
      )}
    </SideBarMenuRow>
  );

  const renderSidebarSocialRow = (row: SocialMediaType) => (
    <SocialRow
      href={row.url}
      onClick={(e) => onClickSocialItem(e, row)}
      rel="noopener noreferrer"
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      target="_blank"
    >
      <SocialIcon
        alt="social icon"
        src={row.icon}
        sx={{
          marginRight: '12px',
        }}
      />
      <SocialItem>{row.text}</SocialItem>
    </SocialRow>
  );

  return (
    <AppSideBar>
      <SidebarMenuSection>
        {routes.map((row: RouteType) => {
          if (row.isComingSoon) {
            return (
              <StyledTooltip key={row.key} title={COMING_SOON_TOOLTIP_TEXT}>
                {renderSidebarMenuRow(row)}
              </StyledTooltip>
            );
          }
          if (!isLeverageClickable && row.key === 'leverage') {
            return (
              <StyledTooltip key={row.key} title={LEVERAGE_TOOLTIP_TEXT}>
                {renderSidebarMenuRow(row)}
              </StyledTooltip>
            );
          }
          return <Box key={row.key}>{renderSidebarMenuRow(row)}</Box>;
        })}
      </SidebarMenuSection>
      <SidebarSocialSection>
        {socialMedias.map((row: SocialMediaType) => {
          if (row.isComingSoon) {
            return (
              <StyledTooltip key={row.key} title={COMING_SOON_TOOLTIP_TEXT}>
                {renderSidebarSocialRow(row)}
              </StyledTooltip>
            );
          }
          return <Box key={row.key}>{renderSidebarSocialRow(row)}</Box>;
        })}

        <Box
          sx={{
            marginLeft: '-8px',
            marginTop: '8px',
          }}
        >
          <ThemeSwitch onToggle={onToggle} value={isDarkMode} />
        </Box>
      </SidebarSocialSection>
    </AppSideBar>
  );
};

export default SideBar;
