import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import walletConnect from './wallet/walletSlice';
import modal from './modal/modalSlice';
import vault from './vault/vaultSlice';
import lend from './lend/lendSlice';
import geolocation from './geoLocation/geoLocationSlice';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export const store = configureStore({
  reducer: {
    walletConnect,
    modal,
    vault,
    lend,
    geolocation,
  },
  middleware: customizedMiddleware,
  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
