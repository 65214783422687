import { FC, useState } from 'react';
import { Box, styled, Typography, useTheme } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ContainedButton, LinkButton } from 'components/common/Button';
import { useAppDispatch } from 'state/hooks';
import { setIsAccountModalOpen, setIsDepositModalOpen, setIsWithdrawModalOpen } from 'state/modal/modalSlice';
import { updateActiveVault } from 'state/vault/vaultSlice';
import { VaultInfo } from 'types/vault';
import { formatPrice } from 'utils/formatPrice';

// vault info row
const VaultInfoRowCotainer = styled(Box)(({ theme }) => ({
  padding: '12px 0px',
  borderTop: '1px solid #E7E5E4',
  borderColor: theme.palette.mode === 'dark' ? '#4B5563' : '#E7E5E4',

  '&:last-child': {
    border: 'none',
  },
}));

// balance label
const VaultName = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#A8A29E',
  fontSize: '14px',
  lineHeight: '17px',
}));

// balance value
const BalanceSymbol = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#A8A29E',
  fontSize: '16px',
  lineHeight: '19px',
}));

// balance value
const BalanceValue = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  fontSize: '20px',
  lineHeight: '24px',
}));

// balance change
const BalanceChange = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  color: theme.palette.mode === 'dark' ? '#34D399' : '#14532D',
  fontSize: '12px',
  lineHeight: '20px',
}));

const BalanceAction = styled(Box)(() => ({
  marginTop: '22px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));

// button
const DepositButton = styled(ContainedButton)(() => ({
  maxWidth: '170px',
}));

const WithdrawButton = styled(ContainedButton)(() => ({
  maxWidth: '170px',
}));

const DetailButton = styled(LinkButton)(() => ({
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '17px',
}));

interface VaultInfoRowProps {
  info: VaultInfo;
}

const VaultInfoRow: FC<VaultInfoRowProps> = ({ info }) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const onToggle = () => {
    setExpanded(!isExpanded);
  };

  const onClickDeposit = () => {
    dispatch(updateActiveVault(info));
    dispatch(setIsAccountModalOpen(false));
    dispatch(setIsDepositModalOpen(true));
  };

  const onClickWithdraw = () => {
    dispatch(updateActiveVault(info));
    dispatch(setIsAccountModalOpen(false));
    dispatch(setIsWithdrawModalOpen(true));
  };

  return (
    <VaultInfoRowCotainer>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <VaultName>{info.name}</VaultName>
        <Box
          onClick={onToggle}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'end', gap: '4px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
              <BalanceSymbol>$</BalanceSymbol>
              <BalanceValue>{formatPrice(info?.tvl)}</BalanceValue>
            </Box>
            <BalanceChange
              sx={{
                color:
                  // eslint-disable-next-line no-nested-ternary
                  Number(info?.tvlChange) >= 0
                    ? theme.palette.mode === 'dark'
                      ? '#34D399'
                      : '#14532D'
                    : theme.palette.mode === 'dark'
                    ? '#F43F5E'
                    : '#F43F5E',
              }}
            >
              {`${Number(info?.tvlChange) >= 0 ? '+' : '-'}$${formatPrice(info?.tvlChange)}`}
            </BalanceChange>
          </Box>
          <Box>{isExpanded ? <ExpandLess sx={{ color: '#9CA3AF' }} /> : <ExpandMore sx={{ color: '#9CA3AF' }} />}</Box>
        </Box>
      </Box>
      {isExpanded && (
        <Box>
          <BalanceAction>
            <DepositButton colorType="primary" onClick={onClickDeposit}>
              Deposit
            </DepositButton>
            <WithdrawButton colorType="teritary" onClick={onClickWithdraw}>
              Withdraw
            </WithdrawButton>
          </BalanceAction>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '18px' }}>
            <DetailButton>See Details</DetailButton>
          </Box>
        </Box>
      )}
    </VaultInfoRowCotainer>
  );
};

export { VaultInfoRow };
