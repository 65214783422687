import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import Header from 'components/Header';
import SideBar from 'components/SideBar';
import {
  AccountModal,
  ConnectModal,
  DepositModal,
  TermsOfServiceModal,
  WalletModal,
  WithdrawModal,
} from 'components/Modal';
import { useAppSelector } from 'state/hooks';
import useAuth from 'hooks/useAuth';

const MainLayoutBox = styled(Box)(({ theme }) => ({
  background: theme.palette.mode === 'dark' ? '#374151' : '#FAFAF9',
  minHeight: '100vh',
}));

const OutLetContainer = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.grey.A100,

  [theme.breakpoints.up('sm')]: {
    marginLeft: '0px',
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: '220px',
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: '300px',
  },
  [theme.breakpoints.up('xl')]: {
    marginLeft: '410px',
  },
}));

const MainLayout: FC = () => {
  const { isWalletModalOpen, isWalletConnectModalOpen, isAccountModalOpen, isDepositModalOpen, isWithdrawModalOpen } =
    useAppSelector((state) => state.modal);
  const { login } = useAuth();

  return (
    <MainLayoutBox>
      <Header />
      <TermsOfServiceModal />
      {isWalletConnectModalOpen && <ConnectModal login={login} />}
      {isWalletModalOpen && <WalletModal />}
      {isAccountModalOpen && <AccountModal />}
      {isDepositModalOpen && <DepositModal />}
      {isWithdrawModalOpen && <WithdrawModal />}

      <Box>
        <SideBar />
        <Suspense fallback={<Box />}>
          <OutLetContainer>
            <Box sx={{ display: 'flex' }}>
              <Outlet />
            </Box>
          </OutLetContainer>
        </Suspense>
      </Box>
    </MainLayoutBox>
  );
};

export default MainLayout;
