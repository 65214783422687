import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Switch } from '@mui/material';

const CustomSwitch = styled(Switch)(() => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

interface CustomSwitchProps {
  value: boolean;
  onToggle: () => void;
}

const ThemeSwitch: FC<CustomSwitchProps> = ({ value, onToggle }) => {
  const onChange = () => {
    onToggle();
  };

  return (
    <Box>
      <CustomSwitch checked={value} onChange={onChange} />
    </Box>
  );
};

export default ThemeSwitch;
