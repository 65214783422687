import { FC, useEffect, useState } from 'react';
import { Box, CircularProgress, Dialog, styled, Typography, useTheme } from '@mui/material';
import { BigNumber, utils } from 'ethers';
import { useDispatch } from 'react-redux';

import { ContainedButton } from 'components/common/Button';
import { arrowIcons, vaultDefaultLogo } from 'config/constants/assets';
import { getExpolorerUrl, shortenTxHash } from 'utils/string';
import { VaultInfo } from 'types/vault';
import { useAppSelector } from 'state/hooks';
import { getTransactionByHash } from 'utils/tenderly';
import { GEOLOCATION_BLACKLIST } from 'config/constants';
import { useSpiceLending } from 'hooks/useSpiceLending';
import { DEFAULT_AGGREGATOR_VAULT, DEFAULT_LEND } from 'config/constants/vault';
import { convertInDays, formatLeverageMaturity } from 'utils/time';
import { activeChainId } from 'utils/web3';
import { PortfolioInfo } from 'types/leverage';
import { getBalanceInEther } from 'utils/formatBalance';
import { setPendingTxHash } from 'state/modal/modalSlice';

const ModalDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container > .MuiPaper-root': {
    borderRadius: '12px',
    maxWidth: '444px',
    width: '100%',
    background: theme.palette.mode === 'dark' ? '#1F2937' : '#FFFFFF',
    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
    padding: '30px 24px',
    border: '1px solid #FFFFFF',
  },
}));

const BackIcon = styled(Box)(() => ({
  marginTop: '-3px',
}));

const BackText = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  color: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
  fontSize: '14px',
  lineHeight: '17px',
  marginLeft: '12px',
}));

const VaultLogo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100px',
  height: '100px',
  background: theme.palette.mode === 'dark' ? '#D9D9D9' : '#F9FAFB',
  margin: 'auto',
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  fontSize: '20px',
  lineHeight: '24px',
  marginTop: '30px',
  display: 'flex',
  justifyContent: 'center',
}));

const InputRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '220px',
  margin: 'auto',
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#000000',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',
}));

const Value = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#6B7280',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',

  a: {
    color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#6B7280',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: '400',
    '&:hover': {
      color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#6B7280',
    },
  },
}));

interface Props {
  isMax?: boolean;
  action: string;
  amount: number;
  borrowApr: number;
  isApproved?: boolean;
  nftId: number;
  loanId?: number;
  vault: VaultInfo | null;
  signatureParam?: any;
  termsParam?: any;
  info: PortfolioInfo;
  onClose: () => void;
  onBack: () => void;
}

const LeverageConfirmModal: FC<Props> = ({
  isMax,
  info,
  action,
  isApproved,
  amount,
  borrowApr,
  nftId,
  loanId,
  signatureParam,
  termsParam,
  vault,
  onClose,
  onBack,
}) => {
  const [status, setStatus] = useState<string>('INITIAL'); // 1.initial, 2.loading, 3.success
  const [error, setError] = useState<string>('');
  const [originAmnt, setOriginAmnt] = useState<number>(0);
  const [newValueFetching, setNewValueFetching] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const { geolocation } = useAppSelector((state) => state.geolocation);
  const { defaultVault } = useAppSelector((state) => state.vault);
  const { onApprovePrologueNft, onObtainLeverage, onIncreaseLeverage, onPartialDecreaseLeverage, onDecreaseLeverage } =
    useSpiceLending(
      info?.lendAddr || DEFAULT_LEND[activeChainId],
      defaultVault?.address || DEFAULT_AGGREGATOR_VAULT[activeChainId]
    );

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const isLoading = status === 'LOADING';
  const isValidNftId = nftId !== undefined && Number(nftId) > 0;
  const isValidAmount = Number(amount) > 0;
  const isConfirmDisabled =
    isLoading || !isValidNftId || (action !== 'Refinance' && action !== 'Renew' && !isValidAmount);

  const isBlackListed = geolocation?.country === undefined || GEOLOCATION_BLACKLIST.includes(geolocation?.country);
  const nftValue = getBalanceInEther(info?.value || BigNumber.from(0));

  useEffect(() => {
    if (action === 'Increase' || action === 'Decrease') {
      if (originAmnt === 0 && amount > 0) {
        setOriginAmnt(amount);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const handleClose = () => {
    if (isLoading) return;
    dispatch(setPendingTxHash(''));
    onClose();
  };

  // obtain loan logic
  const handleInitiateLoan = async () => {
    setStatus('LOADING');
    try {
      await onObtainLeverage(termsParam, signatureParam);

      setNewValueFetching(true);
      setStatus('SUCCESS');

      setTimeout(() => {
        setNewValueFetching(false);
        setError('');
      }, 4000);
    } catch (err: any) {
      setStatus('FAILED');
      if (err.code) {
        setError(err.code);
      } else {
        const failedReason = await getTransactionByHash(pendingTxHash);
        setError(failedReason);
      }
    }
  };

  // increase loan logic
  const handleIncreaseLoan = async () => {
    if (!loanId) return;
    setStatus('LOADING');
    try {
      await onIncreaseLeverage(loanId, termsParam, signatureParam);

      setNewValueFetching(true);
      setStatus('SUCCESS');

      setTimeout(() => {
        setNewValueFetching(false);
        setError('');
      }, 4000);
    } catch (err: any) {
      setStatus('FAILED');
      if (err.code) {
        setError(err.code);
      } else {
        const failedReason = await getTransactionByHash(pendingTxHash);
        setError(failedReason);
      }
    }
  };

  // decrease loan logic
  const handleDecreaseLoan = async (repayAmnt?: string) => {
    if (!loanId) return;
    setStatus('LOADING');
    try {
      if (repayAmnt) {
        await onPartialDecreaseLeverage(loanId, utils.parseEther(repayAmnt).toString()); // "partial repay"
      } else {
        await onDecreaseLeverage(loanId); // "full repay"
      }

      setNewValueFetching(true);
      setStatus('SUCCESS');

      setTimeout(
        () => {
          setNewValueFetching(false);
          setError('');
        },
        repayAmnt ? 4000 : 7000
      );
    } catch (err: any) {
      setStatus('FAILED');
      if (err.code) {
        setError(err.code);
      } else {
        const failedReason = await getTransactionByHash(pendingTxHash);
        setError(failedReason);
      }
    }
  };

  const handleConfirm = async () => {
    if (isBlackListed) return;
    if (isConfirmDisabled) return;

    // approve logic
    if (!isApproved) {
      setStatus('LOADING');
      try {
        await onApprovePrologueNft(nftId);

        setStatus('INITIAL');
        setError('');
      } catch (err: any) {
        setStatus('INITIAL');
        if (err.code) {
          setError(err.code);
        } else {
          const failedReason = await getTransactionByHash(pendingTxHash);
          setError(failedReason);
        }
      }
    } else {
      if (action === 'Obtain') {
        await handleInitiateLoan();
      }
      if (action === 'Increase') {
        await handleIncreaseLoan();
      }
      if (action === 'Decrease') {
        if (isMax) {
          await handleDecreaseLoan();
        } else {
          await handleDecreaseLoan(amount.toString());
        }
      }
      if (action === 'Refinance') {
        await handleIncreaseLoan();
      }
      if (action === 'Renew') {
        await handleIncreaseLoan();
      }
    }
  };

  const getModalTitle = () => {
    switch (action) {
      case 'Obtain':
        if (status === 'INITIAL' || status === 'LOADING') return 'Leverage Terms';
        if (status === 'SUCCESS') return 'Leverage Obtained';
        if (status === 'FAILED') return 'Leverage Obtaine Failed';
        break;

      case 'Increase':
        if (status === 'INITIAL' || status === 'LOADING') return 'New Leverage Terms';
        if (status === 'SUCCESS') return 'Leverage Increased';
        if (status === 'FAILED') return 'Leverage Increase Failed';

        break;

      case 'Decrease':
        if (status === 'INITIAL' || status === 'LOADING') return 'New Leverage Terms';
        if (status === 'SUCCESS') return 'Leverage Decreased';
        if (status === 'FAILED') return 'Leverage Decrease Failed';
        break;

      case 'Refinance':
        if (status === 'INITIAL' || status === 'LOADING') return 'New Leverage Terms';
        if (status === 'SUCCESS') return 'Leverage Refinanced';
        if (status === 'FAILED') return 'Leverage Refinance Failed';
        break;

      case 'Renew':
        if (status === 'INITIAL' || status === 'LOADING') return 'New Leverage Terms';
        if (status === 'SUCCESS') return 'Leverage Renewed';
        if (status === 'FAILED') return 'Leverage Renew Failed';
        break;

      default:
        break;
    }

    return 'Finish';
  };

  const getButtonName = () => {
    if (status === 'LOADING' || newValueFetching) {
      return (
        <>
          Processing...
          <CircularProgress size={16} sx={{ marginLeft: '10px', color: 'white' }} />
        </>
      );
    }

    if (!isApproved) return 'Approve';
    if (status === 'INITIAL') return 'Confirm';

    return 'Finish';
  };

  if (!vault) return null;

  return (
    <ModalDialog maxWidth="xs" onClose={handleClose} open theme={theme}>
      <Box>
        {status === 'INITIAL' && (
          <Box onClick={onBack} sx={{ position: 'absolute', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <BackIcon>
              <img alt="back" src={isDarkMode ? arrowIcons.left.dark : arrowIcons.left.light} />
            </BackIcon>
            <BackText>Back</BackText>
          </Box>
        )}
        <Box>
          <VaultLogo>
            <img alt="logo" src={isDarkMode ? vaultDefaultLogo.dark : vaultDefaultLogo.light} />
          </VaultLogo>
          <ModalTitle>{getModalTitle()}</ModalTitle>
        </Box>
        <Box sx={{ margin: '30px 0px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
          {!vault?.fungible && (
            <InputRow>
              <Label>NFT ID</Label>
              <Box>
                <Value>{`#${nftId}`}</Value>
              </Box>
            </InputRow>
          )}
          {action === 'Decrease' && (
            <InputRow>
              <Label>Repaid</Label>
              <Box>
                <Value>{`Ξ${originAmnt.toFixed(2)}`}</Value>
              </Box>
            </InputRow>
          )}
          {action === 'Increase' && (
            <InputRow>
              <Label>Added</Label>
              <Box>
                <Value>{`Ξ${originAmnt.toFixed(2)}`}</Value>
              </Box>
            </InputRow>
          )}
          {action === 'Obtain' && (
            <InputRow>
              <Label>Added</Label>
              <Box>
                <Value>{`Ξ${amount.toFixed(2)}`}</Value>
              </Box>
            </InputRow>
          )}

          <InputRow>
            <Label>{action === 'Refinance' || action === 'Renew' ? 'NFT Value' : 'New NFT Value'}</Label>
            {status === 'SUCCESS' ? (
              <Box>
                {newValueFetching ? (
                  <CircularProgress size={12} sx={{ color: isDarkMode ? '#ffffff' : '#44403C' }} />
                ) : (
                  <Value>{`Ξ${nftValue.toFixed(2)}`}</Value>
                )}
              </Box>
            ) : (
              <>
                {newValueFetching ? (
                  <Box>
                    <CircularProgress size={12} sx={{ color: isDarkMode ? '#ffffff' : '#44403C' }} />
                  </Box>
                ) : (
                  <Box>
                    {action === 'Decrease' && (
                      <Value>{`Ξ${(nftValue - amount > 0 ? nftValue - amount : 0).toFixed(2)}`}</Value>
                    )}
                    {action === 'Increase' && <Value>{`Ξ${(nftValue + amount).toFixed(2)}`}</Value>}
                    {action === 'Obtain' && <Value>{`Ξ${(nftValue + amount).toFixed(2)}`}</Value>}
                    {action === 'Refinance' && <Value>{`Ξ${nftValue.toFixed(2)}`}</Value>}
                    {action === 'Renew' && <Value>{`Ξ${nftValue.toFixed(2)}`}</Value>}
                  </Box>
                )}
              </>
            )}
          </InputRow>
          {/* hide when max decrease */}
          {action === 'Decrease' && isMax ? (
            <></>
          ) : (
            <InputRow>
              <Label>Borrow APR</Label>
              <Box>
                {action !== 'Decrease' && <Value>{`${((termsParam?.interestRate || 0) / 100).toFixed(2)}%`}</Value>}
                {action === 'Decrease' && <Value>{`${(borrowApr || 0).toFixed(2)}%`}</Value>}
              </Box>
            </InputRow>
          )}
          {/* hide when max decrease */}
          {action === 'Decrease' && isMax ? (
            <></>
          ) : (
            <InputRow>
              <Label>Auto Renew</Label>
              <Box>
                <Value>
                  {action === 'Decrease'
                    ? formatLeverageMaturity(info?.autoRenew || 0)
                    : `${Math.round(convertInDays(termsParam?.duration || 0)) - 14} days`}
                </Value>
              </Box>
            </InputRow>
          )}
          {pendingTxHash && pendingTxHash.length > 0 && (
            <InputRow>
              <Label>Tx Hash</Label>
              <Box>
                <Value>
                  <a href={getExpolorerUrl(pendingTxHash)} rel="noopener noreferrer" target="_blank">
                    {shortenTxHash(pendingTxHash, 4)}
                  </a>
                </Value>
              </Box>
            </InputRow>
          )}
          {error && (
            <InputRow>
              <Label>Reason</Label>
              <Box sx={{ textAlign: 'right' }}>
                <Value>{error}</Value>
              </Box>
            </InputRow>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '72px' }}>
          <ContainedButton
            colorType="primary"
            onClick={status === 'SUCCESS' || status === 'FAILED' ? handleClose : handleConfirm}
            sx={{ maxWidth: '220px' }}
          >
            {getButtonName()}
          </ContainedButton>
        </Box>
      </Box>
    </ModalDialog>
  );
};

export { LeverageConfirmModal };
