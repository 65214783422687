import { FC, useEffect, useState } from 'react';
import { BigNumber } from 'ethers';
import { useDispatch } from 'react-redux';

import { Box, CircularProgress, Dialog, styled, Typography, useTheme } from '@mui/material';
import { ContainedButton } from 'components/common/Button';
import { shortenAddress } from 'utils';
import { vaultDefaultLogo, wantTokenIcons } from 'config/constants/assets';
import { getExpolorerUrl, shortenTxHash } from 'utils/string';
import { VaultInfo } from 'types/vault';
import { getBalanceInEther } from 'utils/formatBalance';
import { useAppSelector } from 'state/hooks';
import { getNftPortfolios } from 'utils/nft';
import { accLoans } from 'utils/lend';
import { setPendingTxHash } from 'state/modal/modalSlice';
import { DEFAULT_AGGREGATOR_VAULT } from 'config/constants/vault';
import { activeChainId } from 'utils/web3';

const ModalDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container > .MuiPaper-root': {
    borderRadius: '12px',
    maxWidth: '444px',
    width: '100%',
    background: theme.palette.mode === 'dark' ? '#1F2937' : '#FFFFFF',
    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
    padding: '20px 24px',
    border: '1px solid #FFFFFF',
  },
}));

const VaultLogo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '120px',
  height: '120px',
  background: theme.palette.mode === 'dark' ? '#D9D9D9' : '#F9FAFB',
  margin: 'auto',
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  fontSize: '20px',
  lineHeight: '24px',
  marginTop: '30px',
  display: 'flex',
  justifyContent: 'center',
}));

const InputRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#000000',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',
}));

const Value = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#6B7280',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',

  a: {
    color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#6B7280',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: '400',
    '&:hover': {
      color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#6B7280',
    },
  },
}));

const TokenSymbol = styled(Box)(() => ({
  marginLeft: '-5px',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  img: {
    width: '100%',
  },
}));

interface Props {
  action: string;
  amount: string;
  allowance?: BigNumber;
  nftId: number;
  walletAddr: string;
  error: string;
  status: string;
  vault: VaultInfo | null;
  tokenType: string;
  onClose: () => void;
}

const CompleteModal: FC<Props> = ({
  action,
  amount,
  allowance,
  tokenType,
  nftId,
  error,
  status,
  walletAddr,
  vault,
  onClose,
}) => {
  const [isLoading, setLoading] = useState<boolean>(true);

  const dispatch = useDispatch();
  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const { vaults } = useAppSelector((state) => state.vault);
  const { data: lendData } = useAppSelector((state) => state.lend);
  const theme = useTheme();
  const loans = accLoans(lendData);

  const currentVault = vaults.find((row: VaultInfo) => row.address === vault?.address);
  const isDarkMode = theme.palette.mode === 'dark';

  const getVaultWithUserTvl = () => {
    let userVaultTvl = BigNumber.from(0);
    let userNftPortfolios: any[] = [];
    if (currentVault?.fungible) {
      userVaultTvl = currentVault?.userInfo?.depositAmnt || BigNumber.from(0);
    } else {
      const userNfts = currentVault?.userInfo?.nftsRaw || [];
      userNftPortfolios =
        currentVault?.address === DEFAULT_AGGREGATOR_VAULT[activeChainId] ? getNftPortfolios(loans, userNfts) : [];

      userNftPortfolios.map((row1: any) => {
        userVaultTvl = userVaultTvl.add(row1.value);
        return row1;
      });
    }

    return { ...currentVault, userVaultTvl, userNftPortfolios };
  };

  const currentVaultWithUserTvl = getVaultWithUserTvl();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const handleClose = () => {
    dispatch(setPendingTxHash(''));
    onClose();
  };

  const getNewAmount = () => {
    // when "Approve" complete modal
    if (action === 'Approve') {
      return getBalanceInEther(allowance || BigNumber.from(0));
    }

    // when "Deposit/Withdraw" complete modal
    if (nftId && nftId > 0) {
      const userNfts = currentVaultWithUserTvl?.userInfo?.nftsRaw || [];
      const userNftPortfolios = getNftPortfolios(loans, userNfts);
      const userNftPortfolio = userNftPortfolios.find((row) => row.tokenId === nftId);

      return getBalanceInEther(userNftPortfolio?.value || BigNumber.from(0));
    }
    return getBalanceInEther(currentVaultWithUserTvl?.userVaultTvl || BigNumber.from(0));
  };

  if (!currentVaultWithUserTvl) return null;

  return (
    <ModalDialog maxWidth="xs" onClose={handleClose} open theme={theme}>
      <Box>
        <Box>
          <VaultLogo>
            <img alt="logo" src={isDarkMode ? vaultDefaultLogo.dark : vaultDefaultLogo.light} />
          </VaultLogo>
          <ModalTitle>{status === 'SUCCESS' ? `${action} Successful!` : `${action} Failed!`}</ModalTitle>
        </Box>
        <Box sx={{ margin: '30px 0px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
          {!currentVaultWithUserTvl?.fungible && (
            <InputRow>
              <Label>NFT ID</Label>
              <Box sx={{ width: '100%', maxWidth: '300px' }}>
                <Value>{`#${nftId}`}</Value>
              </Box>
            </InputRow>
          )}
          {action !== 'Approve' && (
            <InputRow>
              <Label>Amount</Label>
              <Box sx={{ width: '100%', maxWidth: '300px', display: 'flex', alignItems: 'center' }}>
                <TokenSymbol>
                  <img alt="token symbol" src={wantTokenIcons[tokenType]} />
                </TokenSymbol>
                <Value>{`${Number(amount).toFixed(2)}`}</Value>
              </Box>
            </InputRow>
          )}
          {status === 'SUCCESS' && (
            <InputRow>
              <Label>
                {
                  // eslint-disable-next-line no-nested-ternary
                  action === 'Approve'
                    ? 'Allowance'
                    : !currentVaultWithUserTvl?.fungible
                    ? 'NFT Balance'
                    : 'New Balance'
                }
              </Label>
              <Box sx={{ width: '100%', maxWidth: '300px' }}>
                {isLoading ? (
                  <CircularProgress size={12} sx={{ color: isDarkMode ? '#ffffff' : '#44403C' }} />
                ) : (
                  <Value>{`Ξ ${Number(getNewAmount()).toFixed(2)}`}</Value>
                )}
              </Box>
            </InputRow>
          )}
          <InputRow>
            <Label>Wallet</Label>
            <Box sx={{ width: '100%', maxWidth: '300px' }}>
              <Value>{`Account ending in ${shortenAddress(walletAddr)}`}</Value>
            </Box>
          </InputRow>
          <InputRow>
            <Label>Tx Hash</Label>
            <Box sx={{ width: '100%', maxWidth: '300px' }}>
              <Value>
                <a href={getExpolorerUrl(pendingTxHash)} rel="noopener noreferrer" target="_blank">
                  {shortenTxHash(pendingTxHash)}
                </a>
              </Value>
            </Box>
          </InputRow>
          {error && (
            <InputRow>
              <Label>Reason</Label>
              <Box sx={{ width: '100%', maxWidth: '300px' }}>
                <Value>{error}</Value>
              </Box>
            </InputRow>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <ContainedButton colorType="primary" onClick={handleClose} sx={{ maxWidth: '220px' }}>
            {status === 'SUCCESS' ? 'FINISH' : 'Try again'}
          </ContainedButton>
        </Box>
      </Box>
    </ModalDialog>
  );
};

export { CompleteModal };
