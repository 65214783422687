import { FC } from 'react';
import Select, { StylesConfig } from 'react-select';
import { useTheme } from '@mui/material';

interface CustomSelectProps {
  disabled?: boolean;
  options: any[];
  placeholder: string;
  height?: string;
  value: any;
  onSelect: (option: any) => void;
}

const CustomSelect: FC<CustomSelectProps> = ({ value, placeholder, height, disabled, options, onSelect }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const colourStyles: StylesConfig<any> = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: 'transparent',
      borderRadius: '8px',
      width: '100%',
      padding: '2px 2px',
      borderColor: isDarkMode ? '#4B5563 !important' : '#9CA3AF !important',
      boxShadow: state.isFocused ? 'none' : 'none',
      height: height || styles.height,
      '&:hover': {
        borderColor: '#9CA3AF !important',
      },
    }),
    menu: (styles) => ({
      ...styles,
      background: isDarkMode ? '#1F2937' : '#ffffff',
      border: '1px solid #4B5563',
      borderColor: isDarkMode ? '#4B5563' : '#9CA3AF',
      borderRadius: '8px',
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight: '145px',
      oveflowY: 'auto',
      padding: '0px',
      borderRadius: '8px',
    }),
    option: (styles, { data, isDisabled, isSelected }) => {
      const defaultBgColor = isDarkMode ? '#374151' : '#E5E7EB';
      const selectedBgColor = isDarkMode ? '#111827' : '#6B7280';
      const activeBgColor = isDarkMode ? '#374151' : '#ffffff';
      return {
        ...styles,
        color: isSelected ? data.color : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        backgroundColor: isSelected ? selectedBgColor : defaultBgColor,
        margin: '4px 4px',
        width: 'auto',
        borderRadius: '4px',
        height: '36px',
        ':hover': {
          border: '0.5px solid #4B5563',
        },
        ':active': {
          backgroundColor: isSelected ? selectedBgColor : activeBgColor,
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: isDarkMode ? '#ffffff' : '#000000',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '17px',
    }),
  };

  return (
    <div>
      <Select
        components={{
          IndicatorSeparator: () => null,
        }}
        isDisabled={disabled || options.length === 0}
        isSearchable={false}
        onChange={onSelect}
        openMenuOnFocus
        options={options}
        placeholder={placeholder}
        styles={colourStyles}
        value={value}
      />
    </div>
  );
};

export default CustomSelect;
