import { FC } from 'react';
import { Box, Dialog, IconButton, styled, Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setIsAccountModalOpen, setIsDepositModalOpen, setIsWithdrawModalOpen } from 'state/modal/modalSlice';
import { updateActiveVault } from 'state/vault/vaultSlice';

import { Close } from '@mui/icons-material';
import { useWeb3React } from '@web3-react/core';
import { accountImg } from 'config/constants/assets';
import { ContainedButton } from 'components/common/Button';
import { formatPrice } from 'utils/formatPrice';
import { shortenAddress } from 'utils';
import { VaultInfo } from 'types/vault';
import { VaultInfoRow } from './VaultInfoRow';

const ModalDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container > .MuiPaper-root': {
    borderRadius: '12px',
    maxWidth: '416px',
    width: '100%',
    background: theme.palette.mode === 'dark' ? '#1F2937' : '#FFFFFF',
    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
    padding: '24px 28px',
    border: '1px solid #FFFFFF',
  },
}));

const TitleRow = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

// modal title
const ModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  fontSize: '24px',
  lineHeight: '29px',
}));

// close button
const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
  padding: '0',
}));

// account row
const AccountRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  marginTop: '16px',
  paddingBottom: '22px',
  paddingRight: '10px',
  borderBottom: '1px solid #E7E5E4',
  borderColor: theme.palette.mode === 'dark' ? '#4B5563' : '#E7E5E4',
}));

// account image
const AccountImg = styled('img')({});

// account name
const Name = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#6B7280',
  fontSize: '16px',
  lineHeight: '19px',
}));

// account id
const Address = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#6B7280' : '#9CA3AF',
  fontSize: '16px',
  lineHeight: '19px',
}));

// overall balance box
const OverallBalanceBox = styled(Box)(({ theme }) => ({
  marginTop: '42px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '20px',
  // borderBottom: '1px solid #E7E5E4',
  // borderColor: theme.palette.mode === 'dark' ? '#4B5563' : '#E7E5E4',
}));

// balance label
const BalanceLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#000000',
  fontSize: '16px',
  lineHeight: '19px',
}));

// balance value
const BalanceSymbol = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#A8A29E',
  fontSize: '28px',
  lineHeight: '34px',
}));

// balance value
const BalanceValue = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  fontSize: '28px',
  lineHeight: '34px',
}));

// balance change
const BalanceChange = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  color: theme.palette.mode === 'dark' ? '#34D399' : '#14532D',
  fontSize: '14px',
  lineHeight: '17px',
}));

const BalanceAction = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));

const AccountModal: FC = () => {
  const { isAccountModalOpen } = useAppSelector((state) => state.modal);
  const { defaultVault, vaults } = useAppSelector((state) => state.vault);
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const restVaults = vaults.filter((row: VaultInfo) => row.address !== defaultVault?.address);

  const onClose = () => {
    dispatch(setIsAccountModalOpen(false));
  };

  const onClickDeposit = () => {
    dispatch(updateActiveVault(defaultVault));
    dispatch(setIsAccountModalOpen(false));
    dispatch(setIsDepositModalOpen(true));
  };

  const onClickWithdraw = () => {
    dispatch(updateActiveVault(defaultVault));
    dispatch(setIsAccountModalOpen(false));
    dispatch(setIsWithdrawModalOpen(true));
  };

  if (!account) return null;

  return (
    <ModalDialog maxWidth="xs" onClose={onClose} open={isAccountModalOpen}>
      <Box>
        <TitleRow>
          <ModalTitle>Account</ModalTitle>
          <CloseButton onClick={onClose}>
            <Close sx={{ width: 24, height: 24, color: '#9CA3AF' }} />
          </CloseButton>
        </TitleRow>
        <AccountRow>
          <Box>
            <AccountImg alt="account" src={isDarkMode ? accountImg.dark : accountImg.light} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Name>Justina Lai</Name>
            <Address>{shortenAddress(account)}</Address>
          </Box>
        </AccountRow>
      </Box>
      <Box sx={{ maxHeight: '300px', overflow: 'auto', scrollbarWidth: 'thin', paddingRight: '10px' }}>
        <OverallBalanceBox>
          <Box>
            <BalanceLabel>{defaultVault?.name}</BalanceLabel>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px', marginTop: '8px' }}>
              <BalanceSymbol>Ξ</BalanceSymbol>
              <BalanceValue>{formatPrice(defaultVault?.tvl)}</BalanceValue>
            </Box>
            <BalanceChange
              sx={{
                color:
                  // eslint-disable-next-line no-nested-ternary
                  Number(defaultVault?.tvlChange) > 0
                    ? theme.palette.mode === 'dark'
                      ? '#34D399'
                      : '#14532D'
                    : theme.palette.mode === 'dark'
                    ? '#F43F5E'
                    : '#F43F5E',
              }}
            >
              {`${Number(defaultVault?.tvlChange) >= 0 ? '+' : '-'}Ξ${formatPrice(defaultVault?.tvlChange)}`}
            </BalanceChange>
          </Box>
          <BalanceAction>
            <ContainedButton
              colorType="primary"
              onClick={() => {
                onClickDeposit();
              }}
              sx={{ maxWidth: '90px' }}
            >
              Deposit
            </ContainedButton>
            <ContainedButton
              colorType="teritary"
              onClick={() => {
                onClickWithdraw();
              }}
              sx={{ maxWidth: '90px' }}
            >
              Withdraw
            </ContainedButton>
          </BalanceAction>
        </OverallBalanceBox>
        <Box>
          {restVaults.map((vault) => (
            <VaultInfoRow info={vault} key={vault?.address} />
          ))}
        </Box>
      </Box>
    </ModalDialog>
  );
};

export { AccountModal };
