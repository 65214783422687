import { FC, useState } from 'react';
import { Box, Dialog, styled, Typography, useTheme } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { ContainedButton, LinkButton } from 'components/common/Button';
import { ThemeSwitch } from 'components/common/Switch';
import { getItem, setItem } from 'utils/helper';

const ModalDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container > .MuiPaper-root': {
    borderRadius: '12px',
    maxWidth: '510px',
    width: '100%',
    background: theme.palette.mode === 'dark' ? '#1F2937' : '#FFFFFF',
    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
    padding: '24px 28px',
    border: '1px solid #FFFFFF',
  },
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  fontSize: '20px',
  lineHeight: '24px',
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#000000',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',
}));

const VisitLinkButton = styled(LinkButton)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
  fontWeight: '400',
  letterSpacing: '0.06em',
  '&:hover': {
    color: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
  },
}));

const TermsText = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#000000',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',
}));

const TermsOfServiceModal: FC = () => {
  const [isAgreed, setAgreed] = useState<boolean>(getItem('isConsentAgreed') === 'true');
  const [completed, setCompleted] = useState<boolean>(false);
  const theme = useTheme();

  const onToggleTerm = () => {
    setAgreed(!isAgreed);
  };

  const onVisitTerms = () => {
    const termsUrl = 'https://docs.spicefi.xyz/terms-of-service';
    window.open(termsUrl, '_blank');
  };

  const onClickConsent = () => {
    if (!isAgreed) return;
    setItem('isConsentAgreed', 'true');
    setCompleted(true);
  };

  return (
    <ModalDialog maxWidth="xs" open={getItem('isConsentAgreed') !== 'true'}>
      <Box>
        <ModalTitle>Notice of Terms of Service</ModalTitle>
      </Box>
      <Box>
        <Box sx={{ marginTop: '20px' }}>
          <DescriptionText>
            {`By clicking "Consent" below, you signify that you have read, understood, and agree to be bound by the Terms of
          Service.`}
          </DescriptionText>
        </Box>
        <Box sx={{ marginTop: '12px', display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Box>
              <VisitLinkButton onClick={onVisitTerms}>{`Terms of Service. `}</VisitLinkButton>
            </Box>
            <Box>
              <LaunchIcon sx={{ fill: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026', width: '16px' }} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
          <Box
            sx={{
              marginLeft: '-8px',
            }}
          >
            <ThemeSwitch onToggle={onToggleTerm} value={isAgreed} />
          </Box>
          <Box>
            <TermsText>
              I confirm that I have read, understood, and agree to be bound by the Terms of Service.
            </TermsText>
          </Box>
        </Box>
        <Box sx={{ marginTop: '24px', textAlign: 'center' }}>
          <ContainedButton colorType="primary" disabled={!isAgreed} onClick={onClickConsent} sx={{ maxWidth: '140px' }}>
            Consent
          </ContainedButton>
        </Box>
      </Box>
    </ModalDialog>
  );
};

export { TermsOfServiceModal };
