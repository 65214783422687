import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isWalletModalOpen: false,
  isAccountModalOpen: false,
  isDepositModalOpen: false,
  isWithdrawModalOpen: false,
  isWalletConnectModalOpen: false,
  isObtainLeverageModalOpen: false,
  isManageLeverageModalOpen: false,
  selectedVaultId: '',
  pendingTxHash: '',
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setIsWalletModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isWalletModalOpen = action.payload;
    },
    setIsAccountModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isAccountModalOpen = action.payload;
    },
    setIsDepositModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isDepositModalOpen = action.payload;
    },
    setIsWithdrawModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isWithdrawModalOpen = action.payload;
    },
    setIsWalletConnectModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isWalletConnectModalOpen = action.payload;
    },
    setIsObtainLeverageModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isObtainLeverageModalOpen = action.payload;
    },
    setIsManageLeverageModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isManageLeverageModalOpen = action.payload;
    },
    setPendingTxHash: (state, action: PayloadAction<string>) => {
      state.pendingTxHash = action.payload;
    },
  },
});

export const {
  setIsWalletConnectModalOpen,
  setIsWalletModalOpen,
  setIsAccountModalOpen,
  setIsDepositModalOpen,
  setIsWithdrawModalOpen,
  setIsObtainLeverageModalOpen,
  setIsManageLeverageModalOpen,
  setPendingTxHash,
} = applicationSlice.actions;

export default applicationSlice.reducer;
