import { FC, useEffect, useState } from 'react';
import { Box, CircularProgress, Dialog, styled, Theme, Typography, useTheme } from '@mui/material';
import { useAppSelector } from 'state/hooks';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, providers } from 'ethers';

import { ContainedInput } from 'components/common/Input';
import { ContainedButton } from 'components/common/Button';
import { LeverageConfirmModal } from 'components/Modal';
import { GEOLOCATION_BLACKLIST } from 'config/constants';
import { DAY_IN_SECONDS } from 'config/constants/time';
import { PortfolioInfo } from 'types/leverage';
import { getBalanceInEther } from 'utils/formatBalance';
import { getLoanTerms } from 'utils/lend';
import { calculateBorrowApr } from 'utils/apy';
import { getWethAddress } from 'utils/addressHelpers';
import { activeChainId } from 'utils/web3';
import { getLenderByLoanId } from 'state/lend/fetchGlobalLend';

const ModalDialog = styled(Dialog)(
  ({ theme, status, visibility }: { theme: Theme; status: string; visibility: string }) => ({
    '.MuiDialog-container > .MuiPaper-root': {
      borderRadius: '12px',
      maxWidth: status === 'SUCCESS' || status === 'FAILED' ? '444px' : '567px',
      width: '100%',
      background: theme.palette.mode === 'dark' ? '#1F2937' : '#FFFFFF',
      boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
      padding: '30px 24px',
      border: '1px solid #FFFFFF',
      visibility,
    },
  })
);

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  fontSize: '20px',
  lineHeight: '24px',
}));

const InputRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#000000',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',
  width: '100px',
}));

interface Props {
  info: PortfolioInfo;
  onClose: () => void;
}

const RenewLeverageModal: FC<Props> = ({ info, onClose }) => {
  const [status, setStatus] = useState<string>('INITIAL'); // 1.initial, 2.confirming,
  // contract call params
  const [termsParam, setTermsParam] = useState<any>();
  const [signatureParam, setSignatureParam] = useState<any>();
  // loan
  const [loanLender, setLoanLender] = useState<string>('');

  const { account, library } = useWeb3React();
  const theme = useTheme();
  const { defaultVault, activeVault, leverageVaults } = useAppSelector((state) => state.vault);
  const { geolocation } = useAppSelector((state) => state.geolocation);
  const { data: lendData } = useAppSelector((state) => state.lend);

  const currentLend = lendData.find((row: any) => row.address === info?.lendAddr);
  const currentVault = activeVault || defaultVault;

  const { loanId, balance } = info.loan;
  const collateralValue = getBalanceInEther(info.value);
  const loanValue = getBalanceInEther(balance || BigNumber.from(0));
  const nftId = info?.tokenId;
  const isValidNftId = nftId !== undefined;
  const isAcceptDisabled = !isValidNftId;

  const isBlackListed = geolocation?.country === undefined || GEOLOCATION_BLACKLIST.includes(geolocation?.country);

  const fetchLoanLender = async () => {
    if (currentLend?.lenderNote) {
      const loanLenderAddr = await getLenderByLoanId(currentLend.lenderNote, loanId);
      setLoanLender(loanLenderAddr);
    }
  };

  useEffect(() => {
    if (loanId > 0) {
      fetchLoanLender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanId]);

  const getRefinanceApr = () => {
    const loanLenderVault = leverageVaults.find((row: any) => row.address === loanLender);

    if (!loanLenderVault) return 0;

    const additionalDebt = 0;
    const total = getBalanceInEther(loanLenderVault?.totalAssets || BigNumber.from(0));
    const available = getBalanceInEther(loanLenderVault?.wethBalance || BigNumber.from(0));
    const duration = (info?.loan?.terms.duration || 0) / DAY_IN_SECONDS;
    const ltv = loanValue / collateralValue;

    return 100 * calculateBorrowApr(ltv, additionalDebt, total, available, duration);
  };

  const onCloseLeverageModal = () => {
    if (status === 'LOADING') return;
    onClose();
  };

  // increase loan
  const handleIncrease = async (additionalAmount: string) => {
    try {
      const terms = {
        loanAmount: info.loanAmount.toString(),
        duration: 14 * DAY_IN_SECONDS, // 14 days
        collateralAddress: currentVault?.address,
        collateralId: nftId,
        borrower: account,
        currency: getWethAddress(),
        additionalLoanAmount: additionalAmount,
        additionalDuration: 0,
      };

      const domain = {
        name: 'Spice Finance',
        version: '1',
        chainId: activeChainId,
      };

      const LoanTermsRequestType = [
        {
          name: 'loanAmount',
          type: 'uint256',
        },
        {
          name: 'duration',
          type: 'uint32',
        },
        {
          name: 'collateralAddress',
          type: 'address',
        },
        {
          name: 'collateralId',
          type: 'uint256',
        },
        {
          name: 'borrower',
          type: 'address',
        },
        {
          name: 'currency',
          type: 'address',
        },
        {
          name: 'additionalLoanAmount',
          type: 'uint256',
        },
        {
          name: 'additionalDuration',
          type: 'uint32',
        },
      ];

      const types = {
        LoanTerms: LoanTermsRequestType,
      };

      const provider = new providers.Web3Provider(library);
      const signer = provider.getSigner();
      // eslint-disable-next-line no-underscore-dangle
      const signature = await signer._signTypedData(domain, types, terms);

      // Call backend api
      const res = await getLoanTerms(terms, signature, 'extend', activeChainId, loanId);

      const loanterms = {
        ...res.data.data.loanterms,
        loanAmount: BigNumber.from(res.data.data.loanterms.loanAmount.toString()),
      };
      delete loanterms.repayment;

      setTermsParam(loanterms);
      setSignatureParam(res.data.data.signature);

      setTimeout(() => {
        setStatus('SUCCESS');
      }, 1000);
    } catch (err: any) {
      setStatus('FAILED');
      onClose();
    }
  };

  const handleAccept = async () => {
    if (isBlackListed) return;

    setStatus('Confirming');
    await handleIncrease('0');
  };

  const onCloseConfirmModal = () => {
    setStatus('INITIAL');
    onClose();
  };

  const onBack = () => {
    setStatus('INITIAL');
  };

  const getButtonName = () => {
    if (status === 'Confirming')
      return (
        <>
          Processing...
          <CircularProgress size={16} sx={{ marginLeft: '10px', color: 'white' }} />
        </>
      );

    if (status === 'INITIAL') return 'Accept';

    return 'Accept';
  };

  return (
    <>
      {account && status === 'SUCCESS' && (
        <LeverageConfirmModal
          action="Renew"
          amount={0}
          borrowApr={getRefinanceApr()}
          info={info}
          isApproved
          isMax={false}
          loanId={loanId}
          nftId={nftId || 0}
          onBack={onBack}
          onClose={onCloseConfirmModal}
          signatureParam={signatureParam}
          termsParam={termsParam}
          vault={currentVault}
        />
      )}
      <ModalDialog
        maxWidth="xs"
        onClose={onCloseLeverageModal}
        open
        status={status}
        theme={theme}
        visibility={status === 'INITIAL' || status === 'Confirming' ? 'visible' : 'hidden'}
      >
        <Box>
          <Box>
            <ModalTitle>Renew Leverage</ModalTitle>
          </Box>
          <Box sx={{ marginTop: '30px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
            {account && (
              <Box>
                <InputRow>
                  <Label>NFT ID</Label>
                  <Box sx={{ width: '100%' }}>
                    <ContainedInput disabled id="nftId-input" name="nftId" placeholder="" value={nftId} />
                  </Box>
                </InputRow>
              </Box>
            )}
            <InputRow>
              <Label>Wallet</Label>
              <Box sx={{ width: '100%' }}>
                <ContainedInput
                  disabled
                  id="walletAddr-input"
                  name="walletAddr"
                  placeholder="Account ending in •••••••wxyz"
                  value={account}
                />
              </Box>
            </InputRow>
            <InputRow>
              <Label>New APR</Label>
              <Box sx={{ width: '100%' }}>
                <ContainedInput
                  disabled
                  id="apr-input"
                  name="apr"
                  placeholder="APR"
                  value={getRefinanceApr().toFixed(2)}
                />
              </Box>
            </InputRow>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', marginTop: '30px' }}>
            <ContainedButton
              colorType="primary"
              disabled={!account || isAcceptDisabled}
              onClick={handleAccept}
              sx={{ maxWidth: '160px' }}
            >
              {getButtonName()}
            </ContainedButton>
          </Box>
        </Box>
      </ModalDialog>
    </>
  );
};

export { RenewLeverageModal };
