import { FC, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  styled,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setIsWalletConnectModalOpen, setIsWithdrawModalOpen, setPendingTxHash } from 'state/modal/modalSlice';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import { ReactSVG } from 'react-svg';

import { updateActiveVault } from 'state/vault/vaultSlice';
import { useNftVault } from 'hooks/useNftVault';
import { useVault } from 'hooks/useVault';
import { useSpiceLending } from 'hooks/useSpiceLending';
import { getBalanceInEther, getBalanceInWei } from 'utils/formatBalance';
import { fetchVaultUserDataAsync } from 'state/actions';
import { DEFAULT_AGGREGATOR_VAULT, DEFAULT_LEND, DEFAULT_LEVERAGE_VAULT } from 'config/constants/vault';
import { getTransactionByHash } from 'utils/tenderly';
import { ContainedInput } from 'components/common/Input';
import { CustomSelect } from 'components/common/Select';
import { ContainedButton, LinkButton } from 'components/common/Button';
import { CompleteModal } from 'components/Modal';
import { getVaultLiquidWeth, getVaultNftShare, getVaultWithdrawable } from 'state/vault/fetchGlobalVault';
import { GEOLOCATION_BLACKLIST } from 'config/constants';
import { getNftPortfolios } from 'utils/nft';
import { isValidNumber } from 'utils/regex';
import { activeChainId } from 'utils/web3';
import { accLoans } from 'utils/lend';
import { triangleIcon, wantTokenIcons } from 'config/constants/assets';
import { getExpolorerUrl, shortenTxHash } from 'utils/string';

const ModalDialog = styled(Dialog)(({ theme, status }: { theme: Theme; status: string }) => ({
  '.MuiDialog-container > .MuiPaper-root': {
    borderRadius: '12px',
    maxWidth: status === 'SUCCESS' || status === 'FAILED' ? '444px' : '567px',
    width: '100%',
    background: theme.palette.mode === 'dark' ? '#1F2937' : '#FFFFFF',
    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
    padding: '20px 24px',
    border: '1px solid #FFFFFF',
  },
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  fontSize: '20px',
  lineHeight: '24px',
}));

const InputRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#000000',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',
  marginTop: '8px',

  a: {
    color: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
      opacity: 0.7,
    },
  },
}));

const InfoText = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#6B7280',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',
  textAlign: 'right',
  marginTop: '16px',
}));

const ToBeStyledTooltip = ({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  position: 'absolute',
  top: '-10px',
  whiteSpace: 'nowrap',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '10px',
  lineHeight: '12px',
  padding: '4px 6px',
  border: '1px solid transparent',
  background: theme.palette.mode === 'dark' ? 'white' : 'white',
  color: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
  borderColor: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
  borderRadius: '5px',
}));

const TokenSymbol = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '24px',
  width: '40px',
  marginLeft: '-5px',

  padding: '2px 4px',
  cursor: 'pointer',
  borderRadius: '4px',
  border: '1px solid transparent',
  borderColor: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
  background: theme.palette.mode === 'dark' ? 'rgba(187, 191, 198, 0.3)' : 'rgba(199, 199, 199, 0.3)',

  img: {
    height: '100%',
  },
}));

const Arrow = styled(ReactSVG)(({ theme }) => ({
  width: '8px',
  path: {
    fill: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
  },
}));

const TxHashLink = styled('a')(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#6B7280',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',
  textDecoration: 'underline',

  '&:hover': {
    color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#6B7280',
  },
}));

const WithdrawModal: FC = () => {
  const [status, setStatus] = useState<string>('INITIAL'); // 1.initial, 2.loading, 3.success
  const [maxAmount, setMaxAmount] = useState<BigNumber>(BigNumber.from(0));
  const [amount, setAmount] = useState<string>('');
  const [amountInWei, setAmountInWei] = useState<BigNumber>(BigNumber.from(0));
  const [activeNftOption, setActiveNftOption] = useState<any>();
  const [nftOptions, setNftOptions] = useState<any[]>([]);
  const [error, setError] = useState<string>('');
  const [action, setAction] = useState<string>('');
  const [liquidWeth, setLiquidWeth] = useState<BigNumber | undefined>();
  const [tokenType, setTokenType] = useState<string>('WETH');

  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const theme = useTheme();
  const { isWithdrawModalOpen, pendingTxHash } = useAppSelector((state) => state.modal);
  const { defaultVault, activeVault } = useAppSelector((state) => state.vault);
  // const { loanRatio, userInfo: userLoans } = useAppSelector((state) => state.lend);
  const { data: lendData } = useAppSelector((state) => state.lend);
  const { geolocation } = useAppSelector((state) => state.geolocation);
  const currentVault = activeVault || defaultVault;

  const {
    onWithdraw: onNftVaultWithdraw,
    onWithdrawETH: onNftVaultWithdrawETH,
    onRedeem: onNftVaultRedeem,
    onRedeemETH: onNftVaultRedeemETH,
  } = useNftVault(currentVault?.address || DEFAULT_AGGREGATOR_VAULT[activeChainId]);
  const {
    onWithdraw: onVaultWithdraw,
    onWithdrawETH: onVaultWithdrawETH,
    onRedeem: onVaultRedeem,
    onRedeemETH: onVaultRedeemETH,
  } = useVault(currentVault?.address || DEFAULT_LEVERAGE_VAULT[activeChainId]);

  const nftId = activeNftOption?.value;
  const isFungible = currentVault?.fungible;
  const loans = accLoans(lendData);
  const selectedLoan = loans.find((row: any) => row.tokenId === nftId);
  const currentLend = lendData.find((row: any) => row.address === selectedLoan?.lendAddr);

  const isBlackListed = geolocation?.country === undefined || GEOLOCATION_BLACKLIST.includes(geolocation?.country);
  const userNfts = (currentVault?.userInfo && currentVault?.userInfo?.nfts) || [];
  const userNftsRaw = (currentVault?.userInfo && currentVault?.userInfo?.nftsRaw) || [];
  const isLoading = status === 'LOADING';
  const isFetching = !currentVault?.userInfo?.tokenBalance;

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // flag to confirm nft is escrowed or not
  const isEscrowed = nftId && !userNfts.includes(nftId);
  const isInsufficientAmnt =
    maxAmount &&
    amountInWei &&
    amountInWei.gt(BigNumber.from(0)) &&
    maxAmount.gt(BigNumber.from(0)) &&
    amountInWei.gt(maxAmount);
  const isNegativeAmnt = amount.length > 0 && maxAmount && maxAmount.lt(BigNumber.from(0));
  const userNftPortfolios =
    currentVault?.address === DEFAULT_AGGREGATOR_VAULT[activeChainId]
      ? getNftPortfolios(loans || [], userNftsRaw || [])
      : [];
  const nftPortfolio = userNftPortfolios.find((row: any) => row.tokenId === nftId);

  const { onLendingWithdraw, onLendingWithdrawETH } = useSpiceLending(
    DEFAULT_LEND[activeChainId],
    defaultVault?.address || DEFAULT_AGGREGATOR_VAULT[activeChainId]
  );

  // check if vault is deprecated
  const isDeprecated = !!currentVault?.leverage && !!currentVault?.deprecated;

  const fetchMaxAmount = async () => {
    if (!currentVault) return;

    if (isFungible) {
      const res = await getVaultWithdrawable(
        currentVault,
        currentVault?.userInfo?.userMaxRedeemable || BigNumber.from(0)
      );
      setMaxAmount(res);
      return;
    }

    if (nftPortfolio) {
      if (nftPortfolio.loan && nftPortfolio.loan.balance) {
        const share = await getVaultNftShare(currentVault, nftPortfolio.tokenId);
        const collateralAmnt = await getVaultWithdrawable(currentVault, share);

        let lendMaxWithdrawable = collateralAmnt.sub(nftPortfolio.loan.repayAmount).sub(
          BigNumber.from(10000)
            .mul(nftPortfolio.loan.repayAmount)
            .div(BigNumber.from(10000 * (currentLend?.loanRatio || 0)))
        );
        lendMaxWithdrawable = BigNumber.from(99).mul(lendMaxWithdrawable).div(BigNumber.from(100));
        setMaxAmount(lendMaxWithdrawable);
      } else {
        const res = await getVaultWithdrawable(currentVault, nftPortfolio?.tokenShare || BigNumber.from(0));
        setMaxAmount(res);
      }
    }
  };

  const getLiquidWeth = async () => {
    setLiquidWeth(undefined);

    if (!currentVault) return;

    const liquidWethBal = await getVaultLiquidWeth(currentVault);
    setLiquidWeth(liquidWethBal);
  };

  useEffect(() => {
    setAmount('');
    setAmountInWei(BigNumber.from(0));
    setActiveNftOption(null);
    setNftOptions([]);
    setTokenType('WETH');
    dispatch(fetchVaultUserDataAsync(account, currentVault));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    setMaxAmount(BigNumber.from(0));
    fetchMaxAmount();

    if (userNftPortfolios && userNftPortfolios.length > 0) {
      const options = userNftPortfolios.map((row: any) => ({ label: row.tokenId, value: row.tokenId }));
      setNftOptions(options);
      setActiveNftOption(options[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loans.length]);

  useEffect(() => {
    getLiquidWeth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVault?.address]);

  useEffect(() => {
    if (!activeNftOption) return;
    fetchMaxAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNftOption]);

  const onSelectNft = (option: any) => {
    if (isFungible) return;
    setActiveNftOption(option);
  };

  const onClose = () => {
    if (status === 'LOADING') return;
    dispatch(setPendingTxHash(''));
    dispatch(updateActiveVault(null));
    dispatch(setIsWithdrawModalOpen(false));
  };

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = e.target.value;
    if (!isValidNumber(newAmount)) return;
    setAmount(newAmount);
    setAmountInWei(getBalanceInWei(Number(newAmount).toString() || '0'));
  };

  const onClickMax = () => {
    if (liquidWeth === undefined) return;
    let max = liquidWeth.gt(maxAmount) ? maxAmount : liquidWeth;
    max = max.gte(BigNumber.from(0)) ? max : BigNumber.from(0);
    setAmount(getBalanceInEther(max).toString());
    setAmountInWei(max);
  };

  const onChangeTokenType = () => {
    if (tokenType === 'WETH') setTokenType('ETH');
    else setTokenType('WETH');
  };

  const onWalletConnect = () => {
    if (isBlackListed) return;

    if (!account) {
      dispatch(setIsWalletConnectModalOpen(true));
    }
  };

  const onCloseCompleteModal = () => {
    setStatus('INITIAL');
    onClose();
  };

  const handleWithdrawETH = async () => {
    const isMax = amountInWei.eq(maxAmount);

    // fungible vault logic
    if (isFungible) {
      if (isMax) {
        await onVaultRedeemETH(currentVault?.userInfo?.userMaxRedeemable || BigNumber.from(0));
      } else {
        await onVaultWithdrawETH(amountInWei);
      }
      return;
    }

    // escrowed nft logic
    if (isEscrowed) {
      await onLendingWithdrawETH(nftPortfolio.loan.loanId || 0, amountInWei);
      return;
    }

    // nft aggregator vault logic
    if (isMax) {
      await onNftVaultRedeemETH(nftId || 0, nftPortfolio.tokenShare);
    } else {
      await onNftVaultWithdrawETH(nftId || 0, amountInWei);
    }
  };

  const handleWithdrawWETH = async () => {
    const isMax = amountInWei.eq(maxAmount);

    // fungible vault logic
    if (isFungible) {
      if (isMax) {
        await onVaultRedeem(currentVault?.userInfo?.userMaxRedeemable || BigNumber.from(0));
      } else {
        await onVaultWithdraw(amountInWei);
      }
      return;
    }

    // escrowed nft logic
    if (isEscrowed) {
      await onLendingWithdraw(nftPortfolio.loan.loanId || 0, amountInWei);
      return;
    }

    // nft aggregator vault logic
    if (isMax) {
      await onNftVaultRedeem(nftId || 0, nftPortfolio.tokenShare);
    } else {
      await onNftVaultWithdraw(nftId || 0, amountInWei);
    }
  };

  const handleWithdraw = async () => {
    setStatus('LOADING');
    setAction('Withdraw');

    try {
      if (tokenType === 'ETH') {
        await handleWithdrawETH();
      }
      if (tokenType === 'WETH') {
        await handleWithdrawWETH();
      }

      setTimeout(() => {
        setStatus('SUCCESS');
        setError('');
      }, 3000);
    } catch (err: any) {
      setStatus('FAILED');
      if (err.code) {
        setError(err.code);
      } else {
        const failedReason = await getTransactionByHash(pendingTxHash);
        setError(failedReason);
      }
    }
  };

  const getButtonName = () => {
    if (status === 'LOADING')
      return (
        <>
          Processing...
          <CircularProgress size={16} sx={{ marginLeft: '10px', color: 'white' }} />
        </>
      );
    return 'Withdraw';
  };

  const tokenTooltipContent = () => (
    <>
      <Box>{`Switch to ${tokenType === 'ETH' ? 'WETH' : 'ETH'}`}</Box>
    </>
  );

  const getSymbolElement = () => (
    <>
      {isDeprecated ? (
        'Ξ'
      ) : (
        <StyledTooltip placement="bottom-start" title={tokenTooltipContent()}>
          <TokenSymbol onClick={onChangeTokenType}>
            <img alt="token symbol" src={wantTokenIcons[tokenType]} />
            <Arrow
              alt="down"
              src={triangleIcon.down}
              sx={{ transform: tokenType === 'ETH' ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </TokenSymbol>
        </StyledTooltip>
      )}
    </>
  );

  return (
    <>
      {account && (status === 'FAILED' || status === 'SUCCESS') && (
        <CompleteModal
          action={action}
          amount={amount}
          error={error}
          nftId={nftId || 0}
          onClose={onCloseCompleteModal}
          status={status}
          tokenType={tokenType}
          vault={currentVault}
          walletAddr={account}
        />
      )}
      <ModalDialog maxWidth="xs" onClose={onClose} open={isWithdrawModalOpen} status={status} theme={theme}>
        <Box>
          <Box>
            <ModalTitle>
              {`Withdraw ${tokenType === 'WETH' ? 'wETH' : 'ETH'} from ${
                currentVault?.readable || currentVault?.name || ''
              }`}
            </ModalTitle>
          </Box>
          <Box sx={{ marginTop: '30px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
            {account && !isFungible && (
              <Box>
                <InputRow>
                  <Label>NFT ID</Label>
                  <Box sx={{ width: '100%', maxWidth: isMobile ? '220px' : '400px' }}>
                    <CustomSelect
                      disabled={isLoading}
                      height="44px"
                      onSelect={onSelectNft}
                      options={nftOptions}
                      placeholder=""
                      value={activeNftOption}
                    />
                  </Box>
                </InputRow>
                {activeNftOption === undefined && (
                  <Box sx={{ width: '100%', maxWidth: isMobile ? '220px' : '400px', float: 'right' }}>
                    <ErrorText>
                      <span>{`You need a Prologue nft to access the vault. `}</span>
                    </ErrorText>
                  </Box>
                )}
              </Box>
            )}
            <Box>
              <InputRow>
                <Label>Amount</Label>
                <Box sx={{ width: '100%', maxWidth: isMobile ? '220px' : '400px' }}>
                  <ContainedInput
                    disabled={isLoading || (!isFungible && (activeNftOption === undefined || activeNftOption === null))}
                    hasMax={!!account && !isFetching && liquidWeth !== undefined}
                    id="amount-input"
                    name="amount"
                    onChange={onChangeAmount}
                    onClickMax={onClickMax}
                    placeholder="0.0"
                    symbol={getSymbolElement()}
                    value={amount}
                  />
                </Box>
              </InputRow>
              {!isLoading && isInsufficientAmnt && (
                <Box sx={{ width: '100%', maxWidth: isMobile ? '220px' : '400px', float: 'right' }}>
                  <ErrorText>
                    {`The maximum amount you can withdraw from your position is: ${getBalanceInEther(maxAmount).toFixed(
                      2
                    )} ${tokenType}`}
                  </ErrorText>
                </Box>
              )}
              {!isLoading && isNegativeAmnt && (
                <Box sx={{ width: '100%', maxWidth: isMobile ? '220px' : '400px', float: 'right' }}>
                  <ErrorText>
                    {`A withdrawal will put your leverage position at risk. Decrease leverage by at least Ξ${Math.abs(
                      getBalanceInEther(maxAmount)
                    ).toFixed(3)} to withdraw.`}
                  </ErrorText>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', marginTop: '30px', alignItems: 'center' }}
          >
            <Box>
              {!account && <LinkButton onClick={onWalletConnect}>No wallet connected?</LinkButton>}
              {pendingTxHash && (
                <TxHashLink href={getExpolorerUrl(pendingTxHash)} rel="noopener noreferrer" target="_blank">
                  {shortenTxHash(pendingTxHash, 8)}
                </TxHashLink>
              )}
            </Box>
            <ContainedButton
              colorType="primary"
              disabled={
                !account ||
                isFetching ||
                isLoading ||
                (!isFungible && activeNftOption === undefined) ||
                Number(amount) === 0 ||
                isInsufficientAmnt ||
                isNegativeAmnt
              }
              onClick={handleWithdraw}
              sx={{ maxWidth: '160px' }}
            >
              {getButtonName()}
            </ContainedButton>
          </Box>

          {account && liquidWeth && (
            <InfoText>
              {`${getBalanceInEther(liquidWeth || BigNumber.from(0)).toFixed(2)}  ${
                tokenType === 'WETH' ? 'WETH' : 'ETH'
              } is currently liquid in this vault`}
            </InfoText>
          )}
        </Box>
      </ModalDialog>
    </>
  );
};

export { WithdrawModal };
