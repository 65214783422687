import { FC, useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  Slider,
  styled,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useAppSelector } from 'state/hooks';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, providers, utils } from 'ethers';

import { ContainedInput } from 'components/common/Input';
import { ContainedButton } from 'components/common/Button';
import { LeverageConfirmModal } from 'components/Modal';
import { GEOLOCATION_BLACKLIST } from 'config/constants';
import { leverageTooltipIcon } from 'config/constants/assets';
import { PortfolioInfo } from 'types/leverage';
import { getBalanceInEther } from 'utils/formatBalance';
import { getLoanTerms } from 'utils/lend';
import { calculateBorrowApr, getNetApy } from 'utils/apy';
import { getWethAddress } from 'utils/addressHelpers';
import { activeChainId } from 'utils/web3';
import { DAY_IN_SECONDS, YEAR_IN_SECONDS } from 'config/constants/time';

const ModalDialog = styled(Dialog)(
  ({ theme, status, visibility }: { theme: Theme; status: string; visibility: string }) => ({
    '.MuiDialog-container > .MuiPaper-root': {
      borderRadius: '12px',
      maxWidth: status === 'SUCCESS' || status === 'FAILED' ? '444px' : '567px',
      width: '100%',
      background: theme.palette.mode === 'dark' ? '#1F2937' : '#FFFFFF',
      boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
      padding: '20px 38px',
      border: '1px solid #FFFFFF',
      visibility,
    },
  })
);

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  fontSize: '20px',
  lineHeight: '24px',
}));

const InputRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#000000',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',
  width: '100px',
}));

// slider
const SliderLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#6B7280',
  fontSize: '8px',
  lineHeight: '10px',
  fontWeight: '400',
}));

const SliderValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '400',
  marginTop: '4px',
}));

const Divider = styled(Box)(() => ({
  background: '#D1D5DB',
  width: '1px',
  height: '100%',
}));

const TermsSlider = styled(Slider)(({ theme }) => ({
  height: 2,
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#D1D5DB',

  '& .MuiSlider-rail': {
    opacity: '1',
  },
  '& .MuiSlider-track': {
    border: 'none',
    color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#D1D5DB',
  },
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
    color: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
    backgroundColor: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    background: 'transparent',
    marginTop: '10px',
  },
}));

// tooltip
const ToBeStyledTooltip = ({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  marginBottom: '20px',
  marginRight: '0px !important',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '10px',
  lineHeight: '12px',
  padding: '10px 10px',
  border: '1px solid #BBBFC6',
  background: theme.palette.mode === 'dark' ? '#111827' : '#D6D3D1',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  borderColor: theme.palette.mode === 'dark' ? '#BBBFC6' : '#78716C',
  borderRadius: '5px',
}));

const TooltipIcon = styled(Box)(() => ({
  position: 'absolute',
  right: '-20px',
  top: '-6px',
}));

interface Props {
  info: PortfolioInfo;
  onClose: () => void;
}

const ObtainLeverageModal: FC<Props> = ({ info, onClose }) => {
  const [status, setStatus] = useState<string>('INITIAL'); // 1.initial, 2.loading, 3.success
  const [sliderStep, setSliderStep] = useState<number>(0);
  const [targetAmount, setTargetAmount] = useState<string>('');
  // contract call params
  const [termsParam, setTermsParam] = useState<any>();
  const [signatureParam, setSignatureParam] = useState<any>();
  const [action, setAction] = useState<string>('Obtain');

  const { account, library } = useWeb3React();
  const theme = useTheme();
  const { geolocation } = useAppSelector((state) => state.geolocation);
  const { defaultVault, activeVault, leverageVaults } = useAppSelector((state) => state.vault);
  const { data: lendData } = useAppSelector((state) => state.lend);

  const currentLend = lendData.find((row: any) => row.address === info?.lendAddr);
  const currentVault = activeVault || defaultVault;

  const { balance } = info.loan;
  const collateralValue = getBalanceInEther(info.value);
  const loanValue = getBalanceInEther(balance || BigNumber.from(0));
  const originMaxLtv = currentLend?.loanRatio || 0;

  const nftId = info?.tokenId;
  const isValidNftId = nftId !== undefined;
  const isValidAmount = Number(targetAmount) > 0;
  const isAcceptDisabled = !isValidNftId || !isValidAmount || status === 'Confirming';
  const isBlackListed = geolocation?.country === undefined || GEOLOCATION_BLACKLIST.includes(geolocation?.country);

  const activeLenderVault = leverageVaults.find((row: any) => !row.deprecated);
  const isDarkMode = theme.palette.mode === 'dark';

  // max leverage calculation to prevent utilization is greater than 1
  const lenderWethAvailable = getBalanceInEther(activeLenderVault?.wethBalance || BigNumber.from(0));
  const maxLeverage = Math.min(lenderWethAvailable, originMaxLtv * collateralValue);
  const maxLtv = maxLeverage / collateralValue;

  const getAmount = (step: number) => (step / 100) * maxLeverage;

  const onCloseLeverageModal = () => {
    if (status === 'Confirming') return;
    onClose();
  };

  const getBorrowApr = () => {
    if (sliderStep === 0) return 0;
    if (!activeLenderVault) return 0;

    const additionalDebt = getAmount(sliderStep);
    const total = getBalanceInEther(activeLenderVault?.totalAssets || BigNumber.from(0));
    const available = lenderWethAvailable;
    const duration = 28;
    // const ltv = (loanValue + additionalDebt) / collateralValue;
    const ltv =
      originMaxLtv > 1
        ? getAmount(sliderStep) / (collateralValue + additionalDebt)
        : getAmount(sliderStep) / collateralValue;

    return 100 * calculateBorrowApr(ltv, additionalDebt, total, available, duration);
  };

  const calculateNetApy = () => {
    const loanDuration = 28 * DAY_IN_SECONDS;
    const m = YEAR_IN_SECONDS / loanDuration;
    const borrowApr = getBorrowApr() / 100;
    // eslint-disable-next-line no-restricted-properties
    const borrowApy = Math.pow(1 + borrowApr / m, m) - 1;
    const vaultApy = (defaultVault?.apr || 0) / 100;
    const { value } = info;

    return (
      100 * getNetApy(getBalanceInEther(value) + getAmount(sliderStep), vaultApy, getAmount(sliderStep), borrowApy)
    );
  };

  const onChangeTerms = (e: any) => {
    if (status === 'Confirming') return;
    setSliderStep(e.target.value);
    setTargetAmount(getAmount(e.target.value).toFixed(4));
  };

  const onChangeTargetAmount = (e: any) => {
    if (status === 'Confirming') return;

    if (Number(e.target.value) >= 0) {
      if (e.target.value > maxLeverage) {
        setTargetAmount(Number(maxLeverage).toFixed(4));
        setSliderStep(100);
      } else {
        setTargetAmount(e.target.value);
        setSliderStep((100 * Number(e.target.value)) / maxLeverage);
      }
    }
  };

  const handleAccept = async () => {
    if (isBlackListed) return;
    setStatus('Confirming');

    if (!info?.isApproved) {
      setTimeout(() => {
        setAction('Approve');
        setStatus('SUCCESS');
      }, 1000);

      return;
    }

    // call backend spice oracle price api
    try {
      const terms = {
        loanAmount: utils.parseEther(targetAmount.toString()).toString(),
        duration: 14 * DAY_IN_SECONDS, // 14 days
        collateralAddress: currentVault?.address,
        collateralId: nftId,
        borrower: account,
        currency: getWethAddress(),
        additionalLoanAmount: 0,
        additionalDuration: 0,
      };

      const domain = {
        name: 'Spice Finance',
        version: '1',
        chainId: activeChainId,
      };

      const LoanTermsRequestType = [
        {
          name: 'loanAmount',
          type: 'uint256',
        },
        {
          name: 'duration',
          type: 'uint32',
        },
        {
          name: 'collateralAddress',
          type: 'address',
        },
        {
          name: 'collateralId',
          type: 'uint256',
        },
        {
          name: 'borrower',
          type: 'address',
        },
        {
          name: 'currency',
          type: 'address',
        },
        {
          name: 'additionalLoanAmount',
          type: 'uint256',
        },
        {
          name: 'additionalDuration',
          type: 'uint32',
        },
      ];

      const types = {
        LoanTerms: LoanTermsRequestType,
      };

      const provider = new providers.Web3Provider(library);
      const signer = provider.getSigner();
      // eslint-disable-next-line no-underscore-dangle
      const signature = await signer._signTypedData(domain, types, terms);

      // Call backend api
      const res = await getLoanTerms(terms, signature, 'initiate', activeChainId);

      const loanterms = {
        ...res.data.data.loanterms,
        loanAmount: BigNumber.from(res.data.data.loanterms.loanAmount.toString()),
      };
      delete loanterms.repayment;

      setTermsParam(loanterms);
      setSignatureParam(res.data.data.signature);

      setTimeout(() => {
        setAction('Obtain');
        setStatus('SUCCESS');
      }, 1000);
    } catch (err: any) {
      setStatus('INITIAL');
    }
  };

  const onCloseConfirmModal = () => {
    setStatus('INITIAL');
    onClose();
  };

  const onBack = () => {
    setStatus('INITIAL');
  };

  const getButtonName = () => {
    if (status === 'Confirming')
      return (
        <>
          Processing...
          <CircularProgress size={16} sx={{ marginLeft: '10px', color: 'white' }} />
        </>
      );

    if (status === 'INITIAL') return 'Accept';

    return 'Accept';
  };

  const valueLabelFormat = () => (
    <Box sx={{ textAlign: 'center' }}>
      <SliderLabel>Leverage Amount</SliderLabel>
      <SliderValue>{`Ξ${Number(targetAmount).toFixed(4)}`}</SliderValue>
    </Box>
  );

  const maxLeverageTooltipContent = () => (
    <>
      <Box sx={{ maxWidth: '198px' }}>
        {`There is only ${Number(lenderWethAvailable).toFixed(
          2
        )} WETH available in the Leverage Vault to borrow from. `}
      </Box>
    </>
  );

  return (
    <>
      {/* {account && status === 'SUCCESS' && action === 'Approve' && (
        <LeverageApproveModal info={info} onClose={onCloseConfirmModal} vault={currentVault} />
      )} */}
      {account && status === 'SUCCESS' && action === 'Obtain' && (
        <LeverageConfirmModal
          action={action}
          amount={Number(targetAmount)}
          borrowApr={getBorrowApr()}
          info={info}
          isApproved={info?.isApproved}
          nftId={nftId || 0}
          onBack={onBack}
          onClose={onCloseConfirmModal}
          signatureParam={signatureParam}
          termsParam={termsParam}
          vault={currentVault}
        />
      )}
      <ModalDialog
        maxWidth="xs"
        onClose={onCloseLeverageModal}
        open
        status={status}
        theme={theme}
        visibility={status === 'INITIAL' || status === 'Confirming' ? 'visible' : 'hidden'}
      >
        <Box>
          <Box>
            <ModalTitle>Obtain Leverage</ModalTitle>
          </Box>
          <Box sx={{ marginTop: '30px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <Box>
              <InputRow>
                <Label>NFT ID</Label>
                <Box sx={{ width: '100%' }}>
                  <ContainedInput disabled id="nftId-input" name="nftId" placeholder="" value={nftId} />
                </Box>
              </InputRow>
            </Box>
            <InputRow>
              <Label>Terms</Label>
              <Box sx={{ width: '100%' }}>
                <TermsSlider
                  disabled={status === 'Confirming'}
                  max={100}
                  min={0}
                  onChange={onChangeTerms}
                  size="small"
                  step={10}
                  value={sliderStep}
                  valueLabelDisplay="on"
                  valueLabelFormat={valueLabelFormat}
                />
              </Box>
            </InputRow>
            <InputRow>
              <Label />
              <Box sx={{ width: '100%', marginTop: '-40px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box display="flex" gap="6px">
                    <Box>
                      <SliderLabel>Borrow APR</SliderLabel>
                      <SliderValue>{`${getBorrowApr().toFixed(2)}%`}</SliderValue>
                    </Box>
                    <Divider />
                    <Box>
                      <SliderLabel>Net APY</SliderLabel>
                      <SliderValue>{`${calculateNetApy().toFixed(2)}%`}</SliderValue>
                    </Box>
                  </Box>

                  <Box sx={{ textAlign: 'right' }}>
                    <Box sx={{ position: 'relative' }}>
                      <SliderLabel>Max Leverage / Max LTV</SliderLabel>
                      {maxLtv < originMaxLtv && (
                        <StyledTooltip placement="left-end" title={maxLeverageTooltipContent()}>
                          <TooltipIcon>
                            <img alt="logo" src={isDarkMode ? leverageTooltipIcon.dark : leverageTooltipIcon.light} />
                          </TooltipIcon>
                        </StyledTooltip>
                      )}
                    </Box>
                    <SliderValue>{`Ξ${maxLeverage.toFixed(4)} / ${(maxLtv * 100).toFixed(2)}%`}</SliderValue>
                  </Box>
                </Box>
              </Box>
            </InputRow>
          </Box>
          <InputRow>
            <Label />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '16px',
                marginTop: '20px',
                width: '100%',
              }}
            >
              <Box sx={{ width: '170px' }}>
                <ContainedInput
                  disabled={status === 'Confirming'}
                  id="amount-input"
                  name="amount"
                  onChange={onChangeTargetAmount}
                  placeholder="Enter Leverage"
                  value={targetAmount}
                />
              </Box>
              <ContainedButton
                colorType="primary"
                disabled={!account || isAcceptDisabled}
                onClick={handleAccept}
                sx={{ maxWidth: '160px' }}
              >
                {getButtonName()}
              </ContainedButton>
            </Box>
          </InputRow>
        </Box>
      </ModalDialog>
    </>
  );
};

export { ObtainLeverageModal };
