import random from 'lodash/random';
import { ANKR_NETWORK_URLS, SPICE_RPC_URLS } from 'config/constants/rpc';
import { SupportedChainId } from 'config/constants/chains';

// ethereum mainnet
export const ankrMainnetNodes = [
  ANKR_NETWORK_URLS[SupportedChainId.MAINNET],
  ANKR_NETWORK_URLS[SupportedChainId.MAINNET],
  ANKR_NETWORK_URLS[SupportedChainId.MAINNET],
];

export const spiceMainnetNodes = [
  SPICE_RPC_URLS[SupportedChainId.MAINNET],
  SPICE_RPC_URLS[SupportedChainId.MAINNET],
  SPICE_RPC_URLS[SupportedChainId.MAINNET],
];

// ethereum testnet
export const ankrTestnetNodes = [
  ANKR_NETWORK_URLS[SupportedChainId.GOERLI],
  ANKR_NETWORK_URLS[SupportedChainId.GOERLI],
  ANKR_NETWORK_URLS[SupportedChainId.GOERLI],
];

export const spiceTestnetNodes = [
  SPICE_RPC_URLS[SupportedChainId.GOERLI],
  SPICE_RPC_URLS[SupportedChainId.GOERLI],
  SPICE_RPC_URLS[SupportedChainId.GOERLI],
];

const getNodeUrl = (networkId?: number) => {
  const chainId = networkId || parseInt(process.env.REACT_APP_CHAIN_ID || '1', 10);
  const nodes = chainId === 1 ? spiceMainnetNodes : spiceTestnetNodes;
  const randomIndex = random(0, nodes.length - 1);
  return nodes[randomIndex];
};

export default getNodeUrl;
