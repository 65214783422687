import { FC } from 'react';
import { Button, ButtonProps, styled, useTheme } from '@mui/material';

const StyledButton = styled(Button)<{
  hoverfontcolor: string;
  hoverbgcolor: string;
  fontcolor: string;
  backgroundcolor: string;
  bordercolor: string;
}>(({ hoverfontcolor, hoverbgcolor, fontcolor, backgroundcolor, bordercolor }) => ({
  fontFamily: 'Inter',
  width: '100%',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '17px',
  padding: '13.5px 16px',
  color: `${fontcolor} !important`,
  background: backgroundcolor,
  border: `1px solid ${bordercolor}`,
  '&:hover': {
    color: `${hoverfontcolor} !important`,
    background: hoverbgcolor,
  },
}));

interface ContainedButtonProps extends ButtonProps {
  colorType?: string;
}

const ContainedButton: FC<ContainedButtonProps> = ({
  colorType = 'primary',
  children,
  disabled,
  onClick,
  ...props
}) => {
  const theme = useTheme();

  const getBgColor = () => {
    if (disabled) {
      return theme.palette.mode === 'dark' ? '#BBBFC6' : '#6B7280';
    }

    let bgColor = theme.palette.mode === 'dark' ? '#B06057' : '#8A3026';

    if (colorType === 'primary') {
      bgColor = theme.palette.mode === 'dark' ? '#B06057' : '#8A3026';
    } else if (colorType === 'secondary') {
      bgColor = theme.palette.mode === 'dark' ? '#9CA3AF' : '#78716C';
    } else if (colorType === 'teritary') {
      bgColor = 'transparent';
    }
    return bgColor;
  };

  const getFontColor = () => {
    if (disabled) {
      return theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF';
    }

    let fontColor = theme.palette.mode === 'dark' ? '#F3F4F6' : '#FFFFFF';
    if (colorType === 'primary') {
      fontColor = theme.palette.mode === 'dark' ? '#F3F4F6' : '#FFFFFF';
    } else if (colorType === 'secondary') {
      fontColor = theme.palette.mode === 'dark' ? '#9CA3AF' : '#292524';
    } else if (colorType === 'teritary') {
      fontColor = theme.palette.mode === 'dark' ? '#9CA3AF' : '#292524';
    }
    return fontColor;
  };

  const getBorderColor = () => {
    if (disabled) {
      return 'transparent';
    }

    let borderColor = theme.palette.mode === 'dark' ? '#9CA3AF' : '#78716C';
    if (colorType === 'primary') {
      borderColor = theme.palette.mode === 'dark' ? '#B06057' : '#FFFFFF';
    } else if (colorType === 'secondary') {
      borderColor = theme.palette.mode === 'dark' ? '#9CA3AF' : '#292524';
    } else if (colorType === 'teritary') {
      borderColor = theme.palette.mode === 'dark' ? '#9CA3AF' : '#78716C';
    }
    return borderColor;
  };

  const getHoverBgColor = () => {
    if (disabled) {
      return theme.palette.mode === 'dark' ? '#BBBFC6' : '#6B7280';
    }

    let hoverBgColor = theme.palette.mode === 'dark' ? '#9CA3AF' : '#78716C';

    if (colorType === 'primary') {
      hoverBgColor = theme.palette.mode === 'dark' ? '#D58F87' : '#772118';
    } else if (colorType === 'secondary') {
      hoverBgColor = theme.palette.mode === 'dark' ? '#4F545F' : '#A8A29E';
    } else if (colorType === 'teritary') {
      hoverBgColor = theme.palette.mode === 'dark' ? '#9CA3AF' : '#000000';
    }
    return hoverBgColor;
  };

  const getHoverFontColor = () => {
    let hoverFontColor = theme.palette.mode === 'dark' ? '#F3F4F6' : '#FFFFFF';
    if (colorType === 'primary') {
      hoverFontColor = theme.palette.mode === 'dark' ? '#F3F4F6' : '#FFFFFF';
    } else if (colorType === 'secondary') {
      hoverFontColor = theme.palette.mode === 'dark' ? '#F3F4F6' : '#FFFFFF';
    } else if (colorType === 'teritary') {
      hoverFontColor = theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF';
    }
    return hoverFontColor;
  };

  return (
    <StyledButton
      backgroundcolor={getBgColor()}
      bordercolor={getBorderColor()}
      disabled={disabled}
      fontcolor={getFontColor()}
      hoverbgcolor={getHoverBgColor()}
      hoverfontcolor={getHoverFontColor()}
      onClick={onClick}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export { ContainedButton };
