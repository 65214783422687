import { FC } from 'react';
import { Button, InputAdornment, styled, TextField, TextFieldProps, useTheme } from '@mui/material';

const StyledInput = styled(TextField)(() => ({
  width: '100%',
  borderColor: 'none',
  '>div': {
    height: '100%',
  },
}));

const MaxButton = styled(Button)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '15px',
  color: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
  textDecoration: 'none',
  background: 'transparent',
  minWidth: 'min-content',
  padding: '0px',

  '&:hover': {
    background: 'transparent',
    textDecoration: 'none',
    color: theme.palette.mode === 'dark' ? '#B06057' : '#8A3026',
  },
}));

interface ContainedInputProps {
  symbol?: any;
  hasMax?: boolean;
  onClickMax?: () => void;
}

const ContainedInput: FC<TextFieldProps & ContainedInputProps> = ({
  symbol = '',
  hasMax = false,
  onChange,
  onClickMax,
  ...props
}) => {
  const theme = useTheme();

  return (
    <StyledInput
      fullWidth
      inputProps={{
        style: {
          color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '17px',
          padding: '12px',
          paddingLeft: symbol ? '8px' : '0px',
          borderColor: '#9CA3AF',
        },
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ margin: '0px' }}>
            {symbol}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" sx={{ margin: '0px' }}>
            {hasMax && <MaxButton onClick={onClickMax}>MAX</MaxButton>}
          </InputAdornment>
        ),
      }}
      onChange={onChange}
      {...props}
    />
  );
};

export { ContainedInput };
