import { FC, useState } from 'react';
import { Box, CircularProgress, Dialog, styled, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';

import { ContainedButton } from 'components/common/Button';
import { vaultDefaultLogo } from 'config/constants/assets';
import { getExpolorerUrl, shortenTxHash } from 'utils/string';
import { useAppSelector } from 'state/hooks';
import { getTransactionByHash } from 'utils/tenderly';
import { GEOLOCATION_BLACKLIST } from 'config/constants';
import { useSpiceLending } from 'hooks/useSpiceLending';
import { DEFAULT_AGGREGATOR_VAULT, DEFAULT_LEND } from 'config/constants/vault';
import { activeChainId } from 'utils/web3';
import { PortfolioInfo } from 'types/leverage';
import { setPendingTxHash } from 'state/modal/modalSlice';

const ModalDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container > .MuiPaper-root': {
    borderRadius: '12px',
    maxWidth: '444px',
    width: '100%',
    background: theme.palette.mode === 'dark' ? '#1F2937' : '#FFFFFF',
    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
    padding: '30px 24px',
    border: '1px solid #FFFFFF',
  },
}));

const VaultLogo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100px',
  height: '100px',
  background: theme.palette.mode === 'dark' ? '#D9D9D9' : '#F9FAFB',
  margin: 'auto',
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  fontSize: '20px',
  lineHeight: '24px',
  marginTop: '30px',
  display: 'flex',
  justifyContent: 'center',
}));

const InputRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '220px',
  margin: 'auto',
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#BBBFC6' : '#000000',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',
}));

const Value = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#6B7280',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',

  a: {
    color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#6B7280',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: '400',
    '&:hover': {
      color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#6B7280',
    },
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#6B7280',
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: '400',
}));

interface Props {
  info: PortfolioInfo;
  onClose: () => void;
  onContinue: () => void;
}

const LeverageApproveModal: FC<Props> = ({ info, onClose, onContinue }) => {
  const [status, setStatus] = useState<string>('INITIAL'); // 1.initial, 2.loading, 3.success
  const [error, setError] = useState<string>('');

  const dispatch = useDispatch();
  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const { geolocation } = useAppSelector((state) => state.geolocation);
  const { defaultVault } = useAppSelector((state) => state.vault);
  const { onApprovePrologueNft } = useSpiceLending(
    info?.lendAddr || DEFAULT_LEND[activeChainId],
    defaultVault?.address || DEFAULT_AGGREGATOR_VAULT[activeChainId]
  );
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const isLoading = status === 'LOADING';
  const nftId = info?.tokenId || 0;
  const isApproved = info?.isApproved;
  const isValidNftId = nftId !== undefined && Number(nftId) > 0;
  const isConfirmDisabled = isLoading || !isValidNftId;

  const isBlackListed = geolocation?.country === undefined || GEOLOCATION_BLACKLIST.includes(geolocation?.country);

  const handleClose = () => {
    if (isLoading) return;
    dispatch(setPendingTxHash(''));
    onClose();
  };

  const handleConfirm = async () => {
    if (isBlackListed) return;
    if (isConfirmDisabled) return;

    // if approve failed
    if (status === 'FAILED') {
      onClose();
      return;
    }

    // if approve success
    if (status === 'SUCCESS') {
      onContinue();
      return;
    }

    // if initial
    setStatus('LOADING');
    try {
      await onApprovePrologueNft(nftId);
      setTimeout(() => {
        setStatus('SUCCESS');
        setError('');
      }, 1000);
    } catch (err: any) {
      setStatus('FAILED');
      if (err.code) {
        setError(err.code);
      } else {
        const failedReason = await getTransactionByHash(pendingTxHash);
        setError(failedReason);
      }
    }
  };

  const getModalTitle = () => {
    if (status === 'INITIAL' || status === 'LOADING') return 'Approval Request';
    if (status === 'SUCCESS') return 'Approved';
    if (status === 'FAILED') return 'Approve Failed';

    return 'Approved';
  };

  const getMainContent = () => {
    if (status === 'INITIAL' || status === 'LOADING') {
      return (
        <>
          <InputRow>
            <Text>
              {`To interact with Prologue Leverage, you must first approve the Spice Protocol’s request to escrow your
              NFT. `}
            </Text>
          </InputRow>
          <InputRow>
            <Text>{`Hit “Approve” to begin the process. `}</Text>
          </InputRow>
        </>
      );
    }
    if (status === 'SUCCESS') {
      return (
        <>
          <InputRow>
            <Text>{`Your approval request has been processed. `}</Text>
          </InputRow>
          <InputRow>
            <Text>{`Hit “Continue” to obtain leverage. `}</Text>
          </InputRow>
        </>
      );
    }

    return (
      <>
        <InputRow>
          <Text>{`Your approval request has been failed. `}</Text>
        </InputRow>
        <InputRow>
          <Text>{`Please navigate back to the Leverage Page to obtain leverage against your NFT. `}</Text>
        </InputRow>
      </>
    );
  };

  const getButtonName = () => {
    if (status === 'LOADING')
      return (
        <>
          Processing...
          <CircularProgress size={16} sx={{ marginLeft: '10px', color: 'white' }} />
        </>
      );

    if (!isApproved && status === 'INITIAL') return 'Approve';
    if (!isApproved && status === 'FAILED') return 'Finish';
    if (isApproved && status === 'SUCCESS') return 'Continue';

    return 'Finish';
  };

  if (!defaultVault) return null;

  return (
    <ModalDialog maxWidth="xs" onClose={handleClose} open theme={theme}>
      <Box>
        <Box>
          <VaultLogo>
            <img alt="logo" src={isDarkMode ? vaultDefaultLogo.dark : vaultDefaultLogo.light} />
          </VaultLogo>
          <ModalTitle>{getModalTitle()}</ModalTitle>
        </Box>
        <Box sx={{ margin: '30px 0px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
          <InputRow>
            <Label>NFT ID</Label>
            <Box>
              <Value>{`#${nftId}`}</Value>
            </Box>
          </InputRow>
          {pendingTxHash && pendingTxHash.length > 0 && (
            <InputRow>
              <Label>Tx Hash</Label>
              <Box>
                <Value>
                  <a href={getExpolorerUrl(pendingTxHash)} rel="noopener noreferrer" target="_blank">
                    {shortenTxHash(pendingTxHash, 4)}
                  </a>
                </Value>
              </Box>
            </InputRow>
          )}
          {error && (
            <InputRow>
              <Label>Reason</Label>
              <Box sx={{ textAlign: 'right' }}>
                <Value>{error}</Value>
              </Box>
            </InputRow>
          )}
          <>{getMainContent()}</>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '32px' }}>
            <ContainedButton
              colorType="primary"
              disabled={isConfirmDisabled}
              onClick={handleConfirm}
              sx={{ maxWidth: '220px' }}
            >
              {getButtonName()}
            </ContainedButton>
          </Box>
        </Box>
      </Box>
    </ModalDialog>
  );
};

export { LeverageApproveModal };
